/* eslint-disable react/display-name */
import clsx from "clsx";
import React, { forwardRef } from "react";
import { GenericItem } from "types/select";

interface DataListProps {
  id: string;
  options: GenericItem[];
  currSuggestId: string;
  setItem: (item: GenericItem) => void;
  rect?: DOMRect;
  style?: React.CSSProperties;
}

const DataList = forwardRef<HTMLUListElement, DataListProps>(
  ({ id, options, currSuggestId, setItem, rect, style }, ref) => {
    return (
      <ul
        id={id}
        role="listbox"
        ref={ref}
        className="absolute left-0 top-[40px] z-10 max-h-[228px] cursor-pointer overflow-y-auto bg-white !py-2.5 text-sm shadow-md"
        style={{
          width: `${rect?.width}px`,
          ...style,
        }}
      >
        {options.map((item) => (
          <li
            id={item.$id}
            role="option"
            aria-selected={currSuggestId === item.$id}
            value={item.$id}
            className={clsx(
              "whitespace-pre-wrap border border-solid !py-4 px-4 text-[15px] hover:bg-du-light-gray",
              currSuggestId === item.$id
                ? "border-du-skyblue bg-du-light-gray"
                : "border-transparent"
            )}
            key={item.$id}
            onClick={() => setItem(item)}
          >
            {item.$name}
          </li>
        ))}
      </ul>
    );
  }
);

export default DataList;

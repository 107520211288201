import React from "react";
import { angularize } from "react-in-angularjs";

const VeteranBenefits = () => {
  return (
    <section
      aria-labelledby="Veteran Benefits"
      id="veteranBenefits"
      className="border-box -webkit-shadow-[5px 5px 5px 0px rgba(0,0,0,0.1)] moz-shadow-[5px_5px_5px_0px_rgba(255,255,0,1)] relative  mb-[1.25rem] mr-[1.75rem] bg-[#ffffff] p-[1.25rem]"
      style={{
        boxShadow: "5px 5px 5px 0px rgba(0,0,0,0.1)",
        width: "95%",
      }}
    >
      <div className="flex-justify-between">
        <div className="text-left">
          <h3
            className="text-[1.5em]"
            style={{
              fontFamily: "'Roboto', Tahoma, sans-serif",
              fontWeight: "300",
              lineHeight: "1.4em",
            }}
          >
            Veteran Benefits
          </h3>
        </div>
      </div>

      <br />
      <p className="font-sans text-[0.9735rem] font-normal leading-[1.6em] text-[#3c3c43]">
        Explore various Veteran Benefits programs to determine your eligibility.
      </p>
      <br />
      <p className="font-sans text-[0.9735rem] font-normal leading-[1.6em] text-[#3c3c43]">
        Many students using military and veteran benefits mistakenly believe
        they cannot also benefit from submitting the FAFSA. In reality, you may
        qualify for grants or scholarships that do not need to be repaid and
        that can be combined with military and veteran benefits. We encourage
        you to submit the FAFSA to explore all available financial
        opportunities.
      </p>
      <br />
      <div className="flex-justify-between">
        <div className="text-left">
          <a
            id="viewVeteranCertification"
            href="#/veteranCertification"
            className="relative cursor-[url(hand.cur),_pointer] text-[0.9375rem] font-bold text-[#003e6a] hover:underline"
          >
            View Veteran Certification
          </a>
        </div>
        <div className="text-left">
          <a
            id="educationBenefitsForVeterans"
            target="blank"
            href=" https://www.umgc.edu/military-and-veterans/tuition-benefits/veterans"
            className="relative cursor-[url(hand.cur),_pointer] text-[0.9375rem] font-bold text-[#003e6a] hover:underline"
          >
            Education Benefits for Veterans
          </a>
        </div>
        <div className="text-left">
          <a
            id="veteranFileTheFAFSA"
            target="blank"
            href="https://studentaid.gov/h/apply-for-aid/fafsa"
            className="relative cursor-[url(hand.cur),_pointer] text-[0.9375rem] font-bold text-[#003e6a] hover:underline"
          >
            File the FAFSA
          </a>
        </div>
      </div>
    </section>
  );
};

angularize(
  VeteranBenefits,
  "veteranBenefitsReact",
  angular.module("duApp"),
  {}
);
export default VeteranBenefits;

import React, { Fragment } from "react";

interface WeekdaysProps {
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
}

const Weekdays: React.FC<WeekdaysProps> = ({
  monday,
  tuesday,
  wednesday,
  thursday,
  friday,
  saturday,
  sunday,
}) => {
  return (
    <Fragment>
      {monday && <span>Mon </span>}
      {tuesday && <span>Tue </span>}
      {wednesday && <span>Wed </span>}
      {thursday && <span>Thu </span>}
      {friday && <span>Fri </span>}
      {saturday && <span>Sat </span>}
      {sunday && <span>Sun </span>}
    </Fragment>
  );
};

export default Weekdays;

import React from "react";
import { angularize } from "react-in-angularjs";

const HeaderImage = () => {
  return (
    <div className="flex items-center gap-2">
      <a
        href="http://www.umgc.edu"
        className="w-[66px] xs:w-[150px] sm:w-[200px] md:w-[240px] lg:w-[300px]"
      >
        <img
          src="/umgc-apps/common/images/umgc-logo-desktop.svg"
          alt="UMGC: University of Maryland Global Campus"
        />
      </a>
      <span className="h-[30px] w-[1px] bg-du-black md:h-[45px]"></span>
      <h1 className="font-secondary text-[15px] font-thin md:text-[20px]">
        MyUMGC
      </h1>
    </div>
  );
};
angularize(HeaderImage, "headerImageReact", angular.module("duApp"), {});
export default HeaderImage;

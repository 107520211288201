import React from "react";

const ResultError = () => {
  return (
    <div className="mt-4 flex h-[400px] flex-col items-center justify-center">
      <img
        src="/umgc-apps/common/images/monitor-search.png"
        alt="Monitor with many magnifying glasses"
        className="w-[250px] object-contain"
      />
      <p className="text-2xl font-bold">Error</p>
      <p>
        There was an error completing your search, please adjust your search
        criteria and try again.
      </p>
    </div>
  );
};

export default ResultError;

import * as Dialog from "@radix-ui/react-dialog";
import Header from "components/Header";
import ModalWrapper from "components/ModalWrapper";
import Paragraph from "components/Paragraph";
import React, { useState } from "react";

interface RemoveClassModalProps {
  trigger: React.ReactNode;
  action: () => void;
}

const RemoveClassModal: React.FC<RemoveClassModalProps> = ({
  trigger,
  action,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Dialog.Root open={isOpen} onOpenChange={setIsOpen}>
      <Dialog.Trigger
        asChild
        aria-expanded={undefined}
        aria-controls={undefined}
      >
        {trigger}
      </Dialog.Trigger>
      <ModalWrapper
        show={isOpen}
        className="h-[280px] max-h-[280px] w-[55vw]"
        ariaLabelledby="remove-class-label"
      >
        <div className="mb-6 flex justify-between">
          <h2 id="remove-class-label">
            <Header>Remove Class</Header>
          </h2>
          <Dialog.Close
            className="border-none text-3xl"
            aria-label="Close"
            autoFocus
          >
            &times;
          </Dialog.Close>
        </div>
        <Paragraph>
          Are you sure you want to remove this class from your shopping cart?
          You will need to add it back to your cart if you want to register for
          it in the future. If you want to save it for later, leave it in your
          cart, and uncheck the checkbox.
        </Paragraph>
        <div className="mt-[30px] flex flex-row justify-end gap-4 border-0 border-t border-solid border-du-gray pt-[30px]">
          <button
            onClick={() => {
              setIsOpen(false);
            }}
            className="border border-transparent px-4 py-2 font-bold text-du-blue"
          >
            No
          </button>
          <button
            onClick={() => {
              action();
              setIsOpen(false);
            }}
            className="border border-solid border-du-yellow bg-du-yellow px-4 py-2 font-bold"
          >
            Yes
          </button>
        </div>
      </ModalWrapper>
    </Dialog.Root>
  );
};

export default RemoveClassModal;

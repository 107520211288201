import clsx from "clsx";
import { DateTime } from "luxon";
import React, { Fragment, useState } from "react";
import { GradingEnum, GradingModalState } from "types/editGradingModal";

import ConfirmGrading from "../../shared/editGrading/ConfirmGrading";
import EditGrading from "../../shared/editGrading/EditGrading";
import ClassStatus from "./ClassStatus";
import { ClassUI } from "./Course";
import ConfirmClassRemove from "./modals/ConfirmClassRemove";

interface ClassTableDesktopProps {
  career: string;
  classList: ClassUI[];
  handleAddClassToCart: (classItem: ClassUI, grading?: GradingEnum) => void;
  handleRemoveClassFromCart: (classItem: ClassUI) => void;
  impersonate?: "true" | "false";
}

const ClassTableDesktop: React.FC<ClassTableDesktopProps> = ({
  career,
  classList,
  handleAddClassToCart,
  handleRemoveClassFromCart,
  impersonate,
}) => {
  const [currClassItem, setCurrClassItem] = useState<ClassUI | undefined>(
    undefined
  );
  const [gradingModal, setGradingModal] = useState<GradingModalState>({
    currGrading: GradingEnum.Graded,
    newGrading: GradingEnum.Graded,
    isEditModalOpen: false,
    isConfirmModalOpen: false,
  });

  return (
    <table className="all-revert mx-4 hidden border-collapse text-[15px] du-sm:table">
      <tbody>
        {classList.map((classItem, index) => (
          <Fragment key={`${classItem.CLASS_NBR}-${index}`}>
            <tr className="all-revert border-x-[1px] border-b-[1px] border-solid border-du-light-gray bg-[#f5f5f5]">
              <th className="all-revert w-[14%] px-2 py-3.5 font-bold">
                Location
              </th>
              <th className="all-revert w-[20%] px-2 py-3.5 font-bold">
                Days & Times
              </th>
              <th className="all-revert w-[16%] px-2 py-3.5 font-bold">
                Meeting Dates
              </th>
              <th className="all-revert w-[15%] px-2 py-3.5 font-bold">
                Instructor
              </th>
              <th className="all-revert w-[10%] px-2 py-3.5 font-bold">
                Class Number
              </th>
              <th className="all-revert w-[12%] px-2 py-3.5 font-bold">
                Section
              </th>
              <th className="all-revert w-[15%] px-2 py-3.5 font-bold">
                Status
              </th>
            </tr>
            <tr className="all-revert !border-x-[1px] !border-b-[1px] !border-solid !border-du-light-gray">
              <td className="all-revert px-2 py-3.5 text-center">
                {classItem.LOCATION_DESCR}
              </td>
              <td className="all-revert px-2 py-3.5 text-center">
                {classItem.MEETING_SCHEDULE}
              </td>
              <td className="all-revert px-2 py-3.5 text-center">
                {DateTime.fromISO(classItem.START_DT).toFormat("M/d/yyyy")}{" "}
                {DateTime.fromISO(classItem.END_DT).toFormat("M/d/yyyy")}
              </td>
              <td className="all-revert px-2 py-3.5 text-center">
                {classItem.INSTRUCTOR}
              </td>
              <td className="all-revert px-2 py-3.5 text-center">
                {classItem.CLASS_NBR}
              </td>
              <td className="all-revert px-2 py-3.5 text-center">
                {classItem.CLASS_SECTION}
              </td>
              <td className="all-revert px-2 py-3.5 text-center">
                <ClassStatus classItem={classItem} />
              </td>
            </tr>
            <tr className="all-revert !border-x-[1px] !border-b-[1px] !border-solid !border-du-light-gray">
              <td className="all-revert py-3.5 pl-4 pr-2" colSpan={2}>
                {classItem.CLASS_NOTES && classItem.CLASS_NOTES !== " " && (
                  <>
                    <span className="font-bold">Notes: </span>
                    {classItem.CLASS_NOTES}
                  </>
                )}
              </td>
              <td className="all-revert px-2 py-3.5" colSpan={5}>
                <div className="all-revert flex justify-end gap-4">
                  {classItem.showClass &&
                    classItem.ENRL_STAT_DESC === "Open" && (
                      <button
                        disabled={impersonate === "true"}
                        className={clsx(
                          "border-none px-5 py-[10px] font-bold text-du-black hover:bg-du-yellow",
                          impersonate === "true" && "cursor-not-allowed"
                        )}
                        onClick={() => {
                          setCurrClassItem(classItem);
                          setGradingModal((prev) => ({
                            ...prev,
                            isEditModalOpen: true,
                            currGrading: classItem.gradingBasisCart,
                            newGrading: classItem.gradingBasisCart,
                          }));
                        }}
                      >
                        <span className="mr-2 after:inline-block after:font-awesome after:text-xs after:font-light after:text-du-black after:content-['\f304']" />
                        Edit Grading
                      </button>
                    )}
                  {classItem.inCart ? (
                    <ConfirmClassRemove
                      trigger={
                        <button
                          disabled={impersonate === "true"}
                          className={clsx(
                            "border-none px-5 py-[10px] font-bold text-du-red",
                            impersonate === "true" && "cursor-not-allowed"
                          )}
                        >
                          <span className="mr-2 after:inline-block after:font-awesome after:text-xs after:font-light after:text-du-red after:content-['\f2ed']" />
                          Remove from Cart
                        </button>
                      }
                      action={() => {
                        handleRemoveClassFromCart(classItem);
                      }}
                    />
                  ) : (
                    classItem.showClass &&
                    classItem.ENRL_STAT_DESC === "Open" && (
                      <button
                        disabled={impersonate === "true"}
                        className={clsx(
                          "border-none px-5 py-[10px] font-bold text-du-black hover:bg-du-yellow",
                          impersonate === "true" && "cursor-not-allowed"
                        )}
                        onClick={() => {
                          handleAddClassToCart(classItem);
                        }}
                      >
                        <span className="mr-2 after:inline-block after:font-awesome after:text-xs after:font-light after:text-du-black after:content-['\f217']" />
                        Add Class to Cart
                      </button>
                    )
                  )}
                </div>
              </td>
            </tr>
            <tr className="h-5 border-x-0 border-b-[1px] border-solid border-du-light-gray last:border-b-0" />
          </Fragment>
        ))}
      </tbody>
      <EditGrading
        career={career}
        gradingModal={gradingModal}
        setGradingModal={setGradingModal}
        actionButton={(isValidOption, handleOnClick) => (
          <button
            className={clsx(
              "all-revert border-none px-5 py-[10px] font-bold",
              isValidOption
                ? "bg-du-yellow text-du-black"
                : "bg-du-dark-gray text-white"
            )}
            onClick={() => {
              handleOnClick();
            }}
          >
            {isValidOption ? (
              <span className="all-revert mr-2 after:inline-block after:font-awesome after:text-xs after:font-light after:text-du-black after:content-['\f217']" />
            ) : (
              <span className="all-revert mr-2 after:inline-block after:font-awesome after:text-xs after:font-light after:text-white after:content-['\f217']" />
            )}
            Add Class to Cart
          </button>
        )}
      />
      <ConfirmGrading
        gradingModal={gradingModal}
        setGradingModal={setGradingModal}
        action={(grading) => {
          if (currClassItem) {
            handleAddClassToCart(currClassItem, grading);
          }
        }}
      />
    </table>
  );
};

export default ClassTableDesktop;

import * as Dialog from "@radix-ui/react-dialog";
import Header from "components/Header";
import Link from "components/Link";
import ModalWrapper from "components/ModalWrapper";
import Paragraph from "components/Paragraph";
import React from "react";
import { GradingEnum, GradingModalState } from "types/editGradingModal";

interface ConfirmGradingProps {
  action: (grading: GradingEnum) => void;
  gradingModal: GradingModalState;
  setGradingModal: React.Dispatch<React.SetStateAction<GradingModalState>>;
}

const ConfirmGrading: React.FC<ConfirmGradingProps> = ({
  action,
  gradingModal,
  setGradingModal,
}) => {
  return (
    <Dialog.Root
      open={gradingModal.isConfirmModalOpen}
      onOpenChange={(isOpen) => {
        setGradingModal((prev) => ({
          ...prev,
          isConfirmModalOpen: isOpen,
        }));
      }}
    >
      <ModalWrapper
        show={gradingModal.isConfirmModalOpen}
        className="h-[280px] max-h-[280px] w-[55vw]"
      >
        <div className="mb-6 flex justify-between">
          <Header className="!text-3xl">Confirm Grading Change</Header>
          <Dialog.Close className="border-none text-3xl" aria-label="Close">
            &times;
          </Dialog.Close>
        </div>
        {(() => {
          switch (gradingModal.newGrading) {
            case GradingEnum.Graded:
              return (
                <div>
                  <Paragraph>
                    You are opting to take this class Graded. It will count
                    towards your degree requirements and you will receive a
                    letter grade of A, B, C, D, or F on the basis of your
                    performance. To learn more,{" "}
                    <Link href="http://www.umgc.edu/current-students/student-life-and-support/advising.cfm">
                      contact an advisor
                    </Link>
                    .
                  </Paragraph>
                  <Paragraph>
                    Are you sure you want to take this class Graded?
                  </Paragraph>
                </div>
              );
            case GradingEnum.Audid:
              return (
                <div>
                  <Paragraph>
                    You are opting to take this class Audit. No letter grade is
                    given for audited courses, nor are credits earned. Audited
                    courses are listed on the permanent record, with the
                    notation AU. To learn more,{" "}
                    <Link href="http://www.umgc.edu/current-students/student-life-and-support/advising.cfm">
                      contact an advisor
                    </Link>
                    .
                  </Paragraph>
                  <Paragraph>
                    Are you sure you want to take this class Audit?
                  </Paragraph>
                </div>
              );
            case GradingEnum.PassFail:
              return (
                <div>
                  <Paragraph>
                    You are opting to take this class Pass/Fail. The class will
                    only count toward your degree requirements if taken using
                    the Graded option. To learn more,{" "}
                    <Link href="http://www.umgc.edu/current-students/student-life-and-support/advising.cfm">
                      contact an advisor
                    </Link>
                    .
                  </Paragraph>
                  <Paragraph>
                    Are you sure you want to take this class Pass/Fail?
                  </Paragraph>
                </div>
              );
          }
        })()}
        <div className="mt-[30px] flex flex-row justify-end gap-4 border-0 border-t border-solid border-du-gray pt-[30px]">
          <Dialog.Close className="border-0 px-4 py-2 font-bold text-du-blue">
            No
          </Dialog.Close>
          <button
            onClick={() => {
              setGradingModal((prev) => ({
                ...prev,
                isConfirmModalOpen: false,
              }));
              action(gradingModal.newGrading);
            }}
            className="border border-solid border-du-yellow bg-du-yellow px-4 py-2 font-bold"
          >
            OK
          </button>
        </div>
      </ModalWrapper>
    </Dialog.Root>
  );
};

export default ConfirmGrading;

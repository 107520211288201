import React from "react";

interface LinkProps {
  children: React.ReactNode;
  href: string;
}

const Link: React.FC<LinkProps> = ({ children, href }) => {
  return (
    <a
      className="text-[15px] font-bold text-du-blue hover:cursor-pointer hover:underline"
      rel="noreferrer"
      href={href}
      target="_blank"
    >
      {children}
    </a>
  );
};

export default Link;

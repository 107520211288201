import CheckboxAlt from "components/CheckboxAlt";
import { ClassSearchContext } from "pages/newClassSearch/classSearchContext";
import { campusDisplay } from "pages/newClassSearch/display";
import { resetAllEventChannel } from "pages/newClassSearch/events/resetAllEventMsg";
import React, { useContext, useEffect } from "react";

const campusList = ["ADLPH", "EUROP", "ASIA"];

const CampusField = () => {
  const {
    state: {
      fields: { campusCode },
    },
    classSearchState: { setClassResponseUI, setClassResponseStatus },
    dispatch,
  } = useContext(ClassSearchContext);

  useEffect(() => {
    dispatch({
      type: "RESET_ALL",
    });
    resetAllEventChannel.emit("onResetAll");
    setClassResponseUI({
      "@odata.context": "",
      "@odata.count": 0,
      "@odata.nextLink": "",
      value: [],
    });
    setClassResponseStatus("pristine");
  }, [campusCode]);

  return (
    <div className="ml-2 mt-4">
      <p className="mb-2 font-bold">Campus</p>
      <ul className="space-y-4">
        {campusList.map((campus, index) => (
          <li key={index}>
            <div className="flex gap-3">
              <div>
                <CheckboxAlt
                  id={`cs-campus-checkbox-${campus}`}
                  value={campus === campusCode}
                  onChange={() => {
                    dispatch({
                      type: "UPDATE_CAMPUS_CODE",
                      payload: {
                        campusCode: campus,
                      },
                    });
                  }}
                />
              </div>
              <p className="text-sm font-bold">{campusDisplay[campus]}</p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CampusField;

import "./financialAid.css";

import ChevronDown from "icons/ChevronDown";
import ChevronRight from "icons/ChevronRight";
import React, { useEffect, useState } from "react";
import { angularize } from "react-in-angularjs";
import { TodosResponse } from "types/TodosResponse";

interface financialAidTOdos {
  ADMIN_FUNC: string;
  CONTACT: string;
  DESCRIPTION: string;
  DUE_DATE: string;
  INDEX: number;
  STATUS: string;
  TO_DO_ITEM: string;
  YEAR: string;
}

const FinToDescrip = () => {
  console.log("FinToDescrip");

  const [pos, setPOS] = useState(0);
  const [length, setLength] = useState(0); // const [currentIndex, setCurrentIndex] = useState<number>(0);
  const selectedTodoInfo = sessionStorage.getItem("todoList");
  const selectedTodo: financialAidTOdos = selectedTodoInfo
    ? JSON.parse(selectedTodoInfo)
    : null;
  const [ADMIN_FUNC, setADMIN_FUNC] = useState("");
  const [CONTACT, setCONTACT] = useState("");
  const [STATUS, setStatus] = useState("");
  const [YEAR, setYEAR] = useState("");
  const [TO_DO_ITEM, setTO_DO_ITEM] = useState("");
  //
  const [DESCRIPTION, setDESCRIPTION] = useState("");
  const [DUEDATE, setDUEDate] = useState("");
  const [todos, setTodos] = useState<financialAidTOdos[]>([]);

  const goToNext = () => {
    console.log("clicked NExt");
    console.log(pos);
    setPOS(pos + 1);
    const completeTodoInfoSet = sessionStorage.getItem("FinancialTodos");
    const parsedDatarArray: financialAidTOdos[] = completeTodoInfoSet
      ? JSON.parse(completeTodoInfoSet)
      : [];
    setLength(parsedDatarArray.length);
    setADMIN_FUNC(parsedDatarArray[pos].ADMIN_FUNC);
    setCONTACT(parsedDatarArray[pos].CONTACT);
    setStatus(parsedDatarArray[pos].STATUS);
    setYEAR(parsedDatarArray[pos].YEAR);
    setTO_DO_ITEM(parsedDatarArray[pos].TO_DO_ITEM);
    setDESCRIPTION(parsedDatarArray[pos].DESCRIPTION);
    setDUEDate(parsedDatarArray[pos].DUE_DATE);
  };

  const goToPrevious = () => {
    console.log("clicked Previous");
    const completeTodoInfoSet = sessionStorage.getItem("FinancialTodos");
    const parsedDatarArray: financialAidTOdos[] = completeTodoInfoSet
      ? JSON.parse(completeTodoInfoSet)
      : [];
    const posValue = pos - 2;
    console.log(posValue);
    console.log(parsedDatarArray);
    console.log(parsedDatarArray[posValue]);
    setLength(parsedDatarArray.length);
    setADMIN_FUNC(parsedDatarArray[posValue].ADMIN_FUNC);
    setCONTACT(parsedDatarArray[posValue].CONTACT);
    setStatus(parsedDatarArray[posValue].STATUS);
    setYEAR(parsedDatarArray[posValue].YEAR);
    setTO_DO_ITEM(parsedDatarArray[posValue].TO_DO_ITEM);
    setDESCRIPTION(parsedDatarArray[posValue].DESCRIPTION);
    setDUEDate(parsedDatarArray[posValue].DUE_DATE);
    setPOS(pos - 1);
    //console.log(pos - 1);
  };

  const getSelectedData = () => {
    const completeTodoInfoSet = sessionStorage.getItem("FinancialTodos");
    if (completeTodoInfoSet) {
      const parsedDatarArray: financialAidTOdos[] = completeTodoInfoSet
        ? JSON.parse(completeTodoInfoSet)
        : [];
      setLength(parsedDatarArray.length);
      if (Array.isArray(parsedDatarArray)) {
        console.log(parsedDatarArray);
        console.log(selectedTodo);
        setTodos(parsedDatarArray);
      } else {
        console.log("not parsed", parsedDatarArray);
      }

      const index = parsedDatarArray.findIndex(
        (item) => item.INDEX == selectedTodo.INDEX
      );
      if (index == 0) {
        setPOS(index + 1);
        //console.log(parsedDatarArray[index].ADMIN_FUNC);
        setADMIN_FUNC(parsedDatarArray[index].ADMIN_FUNC);
        setCONTACT(parsedDatarArray[index].CONTACT);
        setStatus(parsedDatarArray[index].STATUS);
        setYEAR(parsedDatarArray[index].YEAR);
        setTO_DO_ITEM(parsedDatarArray[index].TO_DO_ITEM);
        setDESCRIPTION(parsedDatarArray[index].DESCRIPTION);
        setDUEDate(parsedDatarArray[index].DUE_DATE);
      } else if (index > 0) {
        setPOS(index + 1);
        //console.log(parsedDatarArray[index].ADMIN_FUNC);
        setADMIN_FUNC(parsedDatarArray[index].ADMIN_FUNC);
        setCONTACT(parsedDatarArray[index].CONTACT);
        setStatus(parsedDatarArray[index].STATUS);
        setYEAR(parsedDatarArray[index].YEAR);
        setTO_DO_ITEM(parsedDatarArray[index].TO_DO_ITEM);
        setDESCRIPTION(parsedDatarArray[index].DESCRIPTION);
        setDUEDate(parsedDatarArray[index].DUE_DATE);
      } else if (index == length) {
        setPOS(-1);
      }
    }
  };

  useEffect(() => {
    console.log("called");
    getSelectedData();
  }, []);

  //const {showButtons,setShowButtons}=useState(false);

  return (
    <div style={{ background: "#fff", padding: "2.813rem 3.125rem" }}>
      <a
        className="cursor-pointer border-none font-bold text-[#003e6a] before:mr-1 before:inline-block before:font-awesome before:text-sm before:font-light before:content-['\f053']"
        href="#/finTodoDetails"
      >
        View All Financial To Dos
      </a>
      <h2
        style={{
          fontFamily: "Roboto",
          fontSize: "1.2rem",
          margin: "1rem 0 .5rem",
          fontWeight: 300,
        }}
      >
        {selectedTodo && TO_DO_ITEM}
      </h2>
      <br />
      <p
        style={{
          fontFamily: "Roboto",
          fontSize: "0.9rem",
          padding: "0.25rem 0",
        }}
      >
        Due Date: {selectedTodo && DUEDATE}
      </p>
      <p
        style={{
          fontSize: "0.9rem",
          padding: "0.25rem 0",
        }}
      >
        Status: {selectedTodo && STATUS}
      </p>
      <p
        style={{
          fontSize: "0.9rem",
          padding: "0.25rem 0",
          display: "flex",
          position: "relative",
        }}
        className="relative cursor-[url(hand.cur),_pointer]"
      >
        <div
          className="cursor-pointer border-none before:inline-block after:ml-1  after:mr-1 after:font-awesome after:text-sm after:font-light after:content-['\f05a']"
          title={
            "Department refers to the UMGC department that initiated the hold."
          }
        >
          Department
          <span className="absolute -left-[230px] -top-[34px] m-4 mx-auto w-[210px] rounded-md bg-du-light-gray p-2 text-[11px] text-sm opacity-0 transition-opacity group-hover:opacity-100">
            Department refers to the UMGC department that initiated the hold.
          </span>
        </div>
        : {selectedTodo && ADMIN_FUNC}
      </p>
      {/* {objData &&
      objData.UC_DU_TO_DO_DOC &&
      objData.UC_DU_TO_DO_DOC.contact == "Please visit http://help.umgc.edu" ? ( */}
      {selectedTodo && CONTACT === "Please visit http://help.umgc.edu" && (
        <p
          style={{
            fontSize: "0.9rem",
            padding: "0.25rem 0 1rem",
            display: "flex",
            position: "relative",
          }}
          className="relative cursor-[url(hand.cur),_pointer]"
        >
          <div
            className="cursor-pointer border-none before:inline-block after:ml-1  after:mr-1 after:font-awesome after:text-sm after:font-light after:content-['\f05a']"
            title={selectedTodo && selectedTodo.CONTACT}
          >
            Contact
          </div>
          :
          <span className="absolute -left-[230px] -top-[34px] m-4 mx-auto w-[210px] rounded-md bg-du-light-gray p-2 text-[11px] text-sm opacity-0 transition-opacity group-hover:opacity-100">
            {selectedTodo && CONTACT}
          </span>
          <a
            href="http://help.umgc.edu"
            target="_blank"
            style={{
              color: "rgba(0, 62, 106, 1)",
              fontWeight: 700,
              paddingLeft: "0.5rem",
            }}
            rel="noreferrer"
          >
            Help Center
          </a>
        </p>
      )}
      <div
        className="fin-to-descrip"
        dangerouslySetInnerHTML={{
          __html: selectedTodo && DESCRIPTION,
        }}
      ></div>
      <div className="fin-details-buttons">
        {pos > 1 ? (
          <button
            className="fin-button before:mr-1 before:inline-block before:font-awesome before:text-sm before:font-light before:content-['\f053']"
            onClick={goToPrevious}
          >
            Previous
          </button>
        ) : (
          <span></span>
        )}
        <span>
          {pos} of {length}
        </span>
        {pos >= 1 && pos < length ? (
          <button
            className="fin-button after:ml-1 after:inline-block after:font-awesome after:text-sm after:font-light after:content-['\f054']"
            onClick={goToNext}
          >
            Next
          </button>
        ) : (
          <span></span>
        )}
      </div>
    </div>
  );
};
angularize(FinToDescrip, "finToDescripReact", angular.module("duApp"), {});

export default FinToDescrip;

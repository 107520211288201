import React from "react";
import type { OptionProps } from "react-select";
import { components } from "react-select";

const { Option } = components;

const highlightInputMatch = (item: string | undefined, keyword: string) => {
  if (!item || !keyword) return item;
  const lowerCasedInputValue = keyword.toLowerCase();
  const hitIndex = item.toLocaleLowerCase().indexOf(lowerCasedInputValue);
  if (hitIndex === -1) return item;
  const before = item.slice(0, hitIndex);
  const match = item.slice(hitIndex, hitIndex + keyword.length);
  const after = item.slice(hitIndex + keyword.length);
  return (
    <span>
      {before}
      <b>{match}</b>
      {after}
    </span>
  );
};

const HighlightedOption = (props: OptionProps) => {
  const inputValue = props.selectProps.inputValue;

  return (
    <Option {...props}>{highlightInputMatch(props.label, inputValue)}</Option>
  );
};

export default HighlightedOption;

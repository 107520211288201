import React from "react";

interface ExternalLinkProps extends React.SVGProps<SVGSVGElement> {
  fillColor?: string;
}

const ExternalLink: React.FC<ExternalLinkProps> = ({ fillColor, ...props }) => {
  return (
    <svg
      width="24"
      height="24"
      version="1.1"
      viewBox="-10 0 596 512"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M195.515 374.828c-4.68555 -4.68652 -4.68555 -12.2842 0 -16.9707l323.15 -323.15l-0.707031 -0.707031l-89.958 0.341797c-6.62695 0 -12 -5.37305 -12 -12v-9.99902c0 -6.62793 5.37207 -12 12 -12l136 -0.342773c6.62695 0 12 5.37207 12 12l-0.342773 136
        c0 6.62695 -5.37305 12 -12 12h-9.99902c-6.62695 0 -12 -5.37305 -12 -12l0.341797 -89.958l-0.707031 -0.707031l-323.15 323.15c-4.68555 4.68555 -12.2832 4.68555 -16.9707 0zM427.515 219.195l-8 8c-2.17188 2.17188 -3.51465 5.17285 -3.51465 8.48438
        c-0.00195312 -0.000976562 0 228.32 0 228.32c0 8.83691 -7.16406 16 -16 16h-352c-8.83594 0 -16 -7.16309 -16 -16v-352c0 -8.83691 7.16406 -16 16 -16h339.976c3.18359 0 6.23535 -1.26367 8.48535 -3.51465l8 -8c7.56055 -7.56055 2.20605 -20.4854 -8.48535 -20.4854
        h-347.976c-26.5098 0 -48 21.4902 -48 48v352c0 26.5098 21.4902 48 48 48h352c26.5098 0 48 -21.4902 48 -48v-236.319c0 -10.6904 -12.9258 -16.0449 -20.4854 -8.48535z"
        fill={fillColor ? fillColor : "#000000"}
      />
    </svg>
  );
};

export default ExternalLink;

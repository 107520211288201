import { DateTime } from "luxon";
import { ClassSearchContext } from "pages/newClassSearch/classSearchContext";
import {
  EField,
  fetchValues,
  IAND,
  IField,
  IFilter,
  IOR,
} from "pages/newClassSearch/classSearchQuery";
import { ClassSearchResponse } from "pages/newClassSearch/types";
import React, { useContext, useEffect, useState } from "react";
import ReactSelect from "react-select";

interface ClassItem {
  catalogNumber: string;
  description: string;
}

const ClassField = () => {
  const [classes, setClasses] = useState<ClassItem[]>([]);

  const {
    state: {
      fields: {
        campusCode,
        academicCareerCode,
        subjectCode,
        locationCodeList,
        classFormatCodeList,
      },
    },
    dispatch,
  } = useContext(ClassSearchContext);

  const fetchClasses = async () => {
    const getFieldList = (onlineOnly: boolean) => {
      const fieldList: (IAND | IOR | IField)[] = [];

      let _campusCode = campusCode;
      if (onlineOnly) _campusCode = "ADLPH";
      fieldList.push({
        campusCode: {
          equals: _campusCode,
        },
      });

      fieldList.push({
        academicCareerCode: {
          equals: academicCareerCode,
        },
      });

      fieldList.push({
        subjectCode: {
          equals: subjectCode,
        },
      });

      fieldList.push({
        classStartDate: {
          gte: DateTime.now().toFormat("yyyy-MM-dd"),
        },
      });

      return fieldList;
    };

    let filter: IFilter;

    if (
      campusCode !== "ADLPH" &&
      (locationCodeList.includes("WEB") || classFormatCodeList.length === 0)
    ) {
      filter = {
        OR: [
          {
            AND: getFieldList(false),
          },
          {
            AND: getFieldList(true),
          },
        ],
      };
    } else {
      filter = {
        AND: getFieldList(false),
      };
    }

    try {
      const classSearchPagination = (await fetchValues({
        filter: filter,
        select: [EField.catalogNumber, EField.courseTitle],
        orderby: {
          catalogNumber: "asc",
        },
      })) as ClassSearchResponse;

      const classList: ClassItem[] = [
        {
          catalogNumber: "All",
          description: "All",
        },
      ];
      classList.push(
        ...classSearchPagination.value.map((item) => ({
          catalogNumber: item.catalogNumber,
          description: `${item.catalogNumber} - ${item.courseTitle}`,
        }))
      );

      setClasses(classList);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (subjectCode !== "") {
      fetchClasses();
    } else {
      dispatch({
        type: "UPDATE_CATALOG_NUMBER",
        payload: {
          catalogNumber: "",
        },
      });
      setClasses([]);
    }
  }, [subjectCode]);

  return (
    <div className="inline-block w-full rounded-md bg-du-light-gray px-4 py-2">
      <div className="mb-2 flex justify-between">
        <p className="text-sm font-bold">Course Number</p>
      </div>
      <div>
        <ReactSelect
          id="cs-class-select-field"
          key={`class-select-field-${subjectCode}`}
          name="class-select-field"
          options={classes}
          onChange={(value) => {
            const newClassCode = (value as ClassItem)?.catalogNumber;
            if (newClassCode === undefined) return;
            dispatch({
              type: "UPDATE_CATALOG_NUMBER",
              payload: {
                catalogNumber: newClassCode === "All" ? "" : newClassCode,
              },
            });
          }}
          getOptionLabel={(option) => option.description}
          getOptionValue={(option) => option.catalogNumber}
          defaultValue={{
            catalogNumber: "All",
            description: "All",
          }}
        />
      </div>
    </div>
  );
};

export default ClassField;

import React, { Children, Reducer, useReducer } from "react";
import { GenericItem } from "types/select";

interface State<T> {
  suggestions: T[];
  currSuggestId: string;
}

interface Action<T> {
  type: string;
  payload: Partial<State<T>>;
}

// TODO: simplify reducer logic or split it with useState
const reducer = <T extends GenericItem>(state: State<T>, action: Action<T>) => {
  switch (action.type) {
    case "UPDATE": {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return {
        suggestions: [],
        currSuggestId: "0",
      };
  }
};

export const useDataList = <T extends GenericItem>(
  listRef: React.RefObject<HTMLUListElement>,
  keepSuggestions: boolean
) => {
  const [{ suggestions, currSuggestId }, dispatch] = useReducer<
    Reducer<State<T>, Action<T>>
  >(reducer, {
    suggestions: [],
    currSuggestId: "0",
  });

  const handleChange = (newSuggestions: T[]) => {
    let suggestId = "";
    if (currSuggestId === "0" && newSuggestions.length > 0) {
      suggestId = newSuggestions[0].$id;
    } else {
      suggestId = currSuggestId;
    }

    dispatch({
      type: "UPDATE",
      payload: {
        suggestions: newSuggestions,
        currSuggestId: suggestId,
      },
    });
  };

  const handleKeyEnter = (): string => {
    const newItem = suggestions.find(
      (suggest) => suggest.$id === currSuggestId
    );
    if (newItem) {
      dispatch({
        type: "UPDATE",
        payload: {
          suggestions: keepSuggestions ? suggestions : [],
          currSuggestId: newItem.$id,
        },
      });
      return currSuggestId;
    }
    return "-1";
  };

  const handleKeyArrow = (key: string) => {
    if (key === "Enter") {
      const newItem = suggestions.find(
        (suggest) => suggest.$id === currSuggestId
      );
      if (newItem)
        dispatch({
          type: "UPDATE",
          payload: {
            suggestions: keepSuggestions ? suggestions : [],
            currSuggestId: "0",
          },
        });
    } else if (key === "ArrowDown") {
      if (suggestions.length === 0) return;
      let currSuggestIndex =
        suggestions.findIndex((suggest) => suggest.$id === currSuggestId) + 1;
      if (currSuggestIndex === suggestions.length) currSuggestIndex = 0;
      const newCurrSuggestId = suggestions[currSuggestIndex].$id;
      if (listRef.current) {
        const currentList = [...listRef.current.children] as HTMLLIElement[];
        const currentListItem = currentList.find(
          (li) => li.getAttribute("value") === newCurrSuggestId
        );
        if (currentListItem)
          currentListItem.scrollIntoView({ block: "nearest" });
      }
      dispatch({
        type: "UPDATE",
        payload: { currSuggestId: newCurrSuggestId },
      });
    } else if (key === "ArrowUp") {
      if (suggestions.length === 0) return;
      let currSuggestIndex =
        suggestions.findIndex((suggest) => suggest.$id === currSuggestId) - 1;
      if (currSuggestIndex < 0) currSuggestIndex = suggestions.length - 1;
      const newCurrSuggestId = suggestions[currSuggestIndex].$id;
      if (listRef.current) {
        const currentList = [...listRef.current.children] as HTMLLIElement[];
        const currentListItem = currentList.find(
          (li) => li.getAttribute("value") === newCurrSuggestId
        );
        if (currentListItem)
          currentListItem.scrollIntoView({ block: "nearest" });
      }
      dispatch({
        type: "UPDATE",
        payload: { currSuggestId: newCurrSuggestId },
      });
    }
  };

  return {
    suggestions,
    currSuggestId,
    handleChange,
    handleKeyEnter,
    handleKeyArrow,
  };
};

import React, { useState } from "react";
import { angularize } from "react-in-angularjs";

const ClassNumber = () => {
  const [value, setValue] = useState("");

  return (
    <div>
      <input
        id="du-class-number"
        title="Enter a Number"
        aria-label="ClassNumberInput"
        name="classNumber"
        type="text"
        pattern="[0-9]*"
        maxLength={5}
        placeholder="e.g. 51463"
        aria-invalid="false"
        value={value}
        onChange={(e) => {
          const newValue = e.target.value;
          if (newValue === "" || /^[0-9\b]+$/.test(newValue)) {
            setValue(newValue);
          }
        }}
        className="all-revert text-[0.9375 rem] box-border h-[40px] w-full  px-[0.6725rem] py-0 pr-[0.6725rem] font-main leading-[38px]"
      />
    </div>
  );
};

angularize(ClassNumber, "classNumberReact", angular.module("duApp"), {});
export default ClassNumber;

import React from "react";

interface CircleInfoProps extends React.SVGProps<SVGSVGElement> {
  fillColor?: string;
}

const CircleInfo: React.FC<CircleInfoProps> = ({ fillColor, ...props }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336l24 0 0-64-24 0c-13.3 0-24-10.7-24-24s10.7-24 24-24l48 0c13.3 0 24 10.7 24 24l0 88 8 0c13.3 0 24 10.7 24 24s-10.7 24-24 24l-80 0c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"
        fill={fillColor ? fillColor : "#3c3c43"}
      ></path>
    </svg>
  );
};

export default CircleInfo;

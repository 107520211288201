import { DateTime } from "luxon";
import { ClassSearchContext } from "pages/newClassSearch/classSearchContext";
import React, { useContext } from "react";

import { SemesterUI } from "./StartDateField";

interface StartDateOptionsProps {
  semesterUIList: SemesterUI[];
  setSemesterUIList: React.Dispatch<React.SetStateAction<SemesterUI[]>>;
}

const StartDateOptions: React.FC<StartDateOptionsProps> = ({
  semesterUIList,
  setSemesterUIList,
}) => {
  const { dispatch } = useContext(ClassSearchContext);

  const startDateOptions = semesterUIList.flatMap((semesterUI) => {
    const startDates = semesterUI.startDates
      .filter((startDate) => startDate.isSelected)
      .map(
        (startDate) =>
          `${semesterUI.semesterName} - ${DateTime.fromISO(
            startDate.startDate
          ).toFormat("MMMM d")}`
      );
    return startDates;
  });

  const handleRemoveStartDateOption = (item: string) => {
    setSemesterUIList((prev) => {
      const newSemesterUIList = [...prev];
      const semesterIndex = newSemesterUIList.findIndex(
        (semester) => semester.semesterName === item.split(" - ")[0]
      );
      const startDateIndex = newSemesterUIList[
        semesterIndex
      ].startDates.findIndex(
        (startDate) =>
          DateTime.fromISO(startDate.startDate).toFormat("MMMM d") ===
          item.split(" - ")[1]
      );
      newSemesterUIList[semesterIndex].startDates[startDateIndex].isSelected =
        false;

      const sessionStartDateList = newSemesterUIList
        .flatMap((semesterUI) => semesterUI.startDates)
        .filter((startDate) => startDate.isSelected)
        .map((startDate) => startDate.startDate);

      dispatch({
        type: "UPDATE_SESSION_START_DATE_LIST",
        payload: {
          sessionStartDateList,
        },
      });

      return newSemesterUIList;
    });
  };

  return (
    <>
      {startDateOptions.length === 0 ? (
        <div key="sd">
          <span className="m-[3px] !py-[4px] px-[2px]">Select...</span>
        </div>
      ) : (
        startDateOptions.map((item, idx) => (
          <p
            key={idx}
            className="m-[2px] inline-block select-none rounded-sm bg-du-light-gray px-[6px] py-[3px]"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {item}
            <button
              className="ml-1 font-bold"
              onClick={() => {
                handleRemoveStartDateOption(item);
              }}
            >
              &times;
            </button>
          </p>
        ))
      )}
    </>
  );
};

export default StartDateOptions;

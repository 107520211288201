import React from "react";

const InitialResults = () => {
  return (
    <div className="mt-4 flex h-[400px] flex-col items-center justify-center">
      <img
        src="/umgc-apps/common/images/monitor-search.png"
        alt="Monitor with many magnifying glasses"
        className="w-[250px] object-contain"
      />
      <p className="text-xl font-bold">Course results will appear here</p>
      <p>
        Select{" "}
        <span className="font-bold">Class Format, Start Date, Location</span> or{" "}
        <span className="font-bold">Subject</span> to search for courses.
      </p>
      <p>Your search results will appear here.</p>
    </div>
  );
};

export default InitialResults;

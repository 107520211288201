import { useDataList } from "components/autocomplete/useDataList";
import { useOnClickOutsideMany } from "hooks/useOnClickOutsideMany";
import React, { RefObject, useEffect, useRef, useState } from "react";
import { GenericItem } from "types/select";

import DataList from "./DataList";

interface AutocompleteProps<T> {
  items: T[];
  currItem: T;
  updateItem: (newItem: T) => void;
  filterItems: (item: T, inputVal: string) => boolean;
  className?: string;
  icon: React.ReactNode;
  listAll: boolean;
  inputId: string;
  iconAction?: boolean;
  resetOnClick?: boolean;
}

const Autocomplete = <T extends GenericItem>({
  items,
  currItem,
  updateItem,
  filterItems,
  className,
  icon,
  listAll,
  inputId,
  iconAction = true,
  resetOnClick = false,
}: AutocompleteProps<T>) => {
  const [input, setInput] = useState("");
  const listRef = useRef<HTMLUListElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const {
    suggestions,
    currSuggestId,
    handleChange: handleChangeDL,
    handleKeyEnter: handleKeyEnterDL,
    handleKeyArrow: handleKeyArrowDL,
  } = useDataList<T>(listRef, false);

  useEffect(() => {
    setInput(currItem.$name);
  }, [currItem]);

  const handleChange = (newValue: string) => {
    const filteredSuggestions = items.filter((item) =>
      filterItems(item, newValue)
    );
    setInput(newValue);
    if (newValue === "") {
      updateItem({
        $id: "",
        $name: "",
      } as T);
    }
    handleChangeDL(filteredSuggestions);
  };

  const setItem = (item: T) => {
    updateItem(item);
    setInput(item.$name);
    // reset suggestions
    handleChangeDL([]);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const currSuggestId = handleKeyEnterDL();
      const newItem = suggestions.find(
        (suggest) => suggest.$id === currSuggestId
      );
      if (newItem && input) {
        setItem(newItem);
      }
    } else if (e.key === "Tab") {
      handleChangeDL([]);
    } else if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      e.preventDefault();
      handleKeyArrowDL(e.key);
    }
  };

  const handleOnClick = (item: T) => {
    setItem(item);
  };

  useOnClickOutsideMany([inputRef as RefObject<HTMLElement>, listRef], () => {
    handleChangeDL([]);
  });

  return (
    <div className="relative">
      <div className="relative">
        <input
          autoComplete="one-time-code"
          id={inputId}
          ref={inputRef}
          className={className}
          type="text"
          value={input}
          onChange={(e) => handleChange(e.target.value)}
          onKeyDown={handleKeyDown}
          onClick={() => {
            if (listAll && input === "") {
              handleChangeDL(items);
            }
            if (resetOnClick) {
              handleChangeDL(items);
              setInput("");
            }
          }}
        />
        <div
          onClick={() => {
            if (iconAction) {
              handleChange(input);
            }
          }}
          className="absolute right-[1px] top-[1px]"
        >
          {icon}
        </div>
      </div>
      {suggestions.length > 0 && (
        <DataList
          suggestions={suggestions}
          currSuggestId={currSuggestId}
          setItem={handleOnClick as (item: GenericItem) => void}
          input={input}
          rect={inputRef.current?.getBoundingClientRect()}
          ref={listRef}
        />
      )}
    </div>
  );
};

export default Autocomplete;

import React, { useState } from "react";
import { angularize } from "react-in-angularjs";

const RadioGroup: React.FC = () => {
  const [selectedValue, setSelectedValue] = useState("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isSubmit, setIsSubmit] = useState(false);
  const isImpersonated = sessionStorage.getItem("impersonateID");

  const isImpersonateMode = isImpersonated !== null;
  const isNoSelected = selectedValue === '2';
  const isButtonDisabled =
    (isImpersonateMode && isNoSelected) || // Impersonated & selected "No" → Disable
    (!isImpersonateMode && !selectedValue) || // Not impersonated & no selection → Disable
    (selectedValue !== '1' && isImpersonateMode); // Impersonated & selected anything other than "Yes" → Disable

  // const [userSelectedNo, setUserSelectedNo] = useState(false);
  // const removeHeaderElement = () => {
  //   const element = document.querySelector(
  //     ".du-module-title.du-experience-subtitle"
  //   );
  //   if (element) {
  //     element.remove(); // Removes the element from the DOM
  //   }
  // };

  const handleClickEvent = (event: React.MouseEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setSelectedValue(value);
    // dispatchCustomEvent(value);
    // if (value === '0') {
    //   setUserSelectedNo(true);
    //   // removeHeaderElement();
    // } else {
    //   setUserSelectedNo(false);
    // }
  };


  const keyDownEvent = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "ArrowLeft" || event.key === "ArrowRight") {
      event.preventDefault();
      // Toggle between "Yes" and "No" on arrow keys
      const newValue = selectedValue === "1" ? "0" : "1";
      setSelectedValue(newValue);
      dispatchCustomEvent(newValue);
    } else if (event.key === "Enter") {
      setSelectedValue(event.currentTarget.value);
      dispatchCustomEvent(event.currentTarget.value);
    }
  };

  const dispatchCustomEvent = (value: string) => {
    const customEvent = new CustomEvent("selecttedValueUpdate", {
      detail: value,
    });
    if (value !== '0') {
      window.dispatchEvent(customEvent);
    }
  };

  const handleRelocate = () => {
    if (selectedValue === '1') {
      setIsSubmit((prev) => {
        if (!prev) {
          dispatchCustomEvent(selectedValue);
        }
        return true;
      });
    } else {
      window.location.reload();
    }
  };

  const handleCancel = () => {
    window.location.reload();
  }

  return (
    <div>
      <div className="mb-[0.125rem] block min-h-[1.5rem] pl-[0.56rem]">
        <div>
          <label className="text-sm font-bold">
            Are you currently serving or have you or your sponsor served in the
            US Armed Forces?
          </label>
        </div>
        <div
          role="radiogroup"
          aria-label="Are you currently serving or have you or your sponsor served in the US Armed Forces?"
        >
          <div
            className="inline-flex items-center pt-2"
            style={{ fontWeight: "bold" }}
          >
            <input
              type="radio"
              name="radioGroup"
              value="1"
              className="border-3 mr-2 h-5 w-5 rounded-full border-black"
              checked={selectedValue === "1"}
              onClick={handleClickEvent}
              onKeyDown={keyDownEvent}
              tabIndex={0}
              aria-labelledby="demo1"
            />
            <span className="mr-4" id="demo1">
              Yes
            </span>
          </div>
          <div
            className="inline-flex items-center"
            style={{ fontWeight: "bold" }}
          >
            <input
              type="radio"
              name="radioGroup"
              value="0"
              checked={selectedValue === "0"}
              className="border-3 mr-2 h-5 w-5 rounded-full border-black"
              onClick={handleClickEvent}
              onKeyDown={keyDownEvent}
              tabIndex={0}
              aria-labelledby="demo2"
            />
            <span id="demo2">No</span>
          </div>
        </div>
        <div className="flex space-x-4 justify-end">
          <button type="button" aria-label="cancel" onClick={handleCancel} className="bg-white border-[0.063rem] border-[#FDBF38] text-black font-bold text-[0.9375rem] leading-[1.2] px-5 py-[10px] inline-block no-underline">
            Cancel
          </button>
          <button
            type="button"
            aria-label="Submit"
            onClick={handleRelocate}
            disabled={isButtonDisabled}
            className={`font-bold text-[0.9375rem] leading-[1.2] px-5 py-[10px] inline-block no-underline rounded-none text-center ${isButtonDisabled
                ? "bg-[rgba(173,173,173,1)] border-[rgba(173,173,173,1)] text-white cursor-not-allowed"
                : "bg-[#FDBF38] border-[#FDBF38] text-black cursor-pointer"
              }`}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

angularize(RadioGroup, "radioReact", angular.module("duApp"), {});
export default RadioGroup;

import * as Dialog from "@radix-ui/react-dialog";
import Header from "components/Header";
import ModalWrapper from "components/ModalWrapper";
import Paragraph from "components/Paragraph";
import React, { useState } from "react";

import { CourseUI } from "../Course";

interface CourseDetailsProps {
  trigger: React.ReactNode;
  courseItem: CourseUI;
}

const CourseDetails: React.FC<CourseDetailsProps> = ({
  trigger,
  courseItem,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Dialog.Root open={isOpen} onOpenChange={setIsOpen}>
      <Dialog.Trigger asChild>{trigger}</Dialog.Trigger>
      <ModalWrapper show={isOpen} className="h-[530px] w-[55vw]">
        <div className="mb-6 flex justify-between">
          <Header>Course Details</Header>
          <Dialog.Close className="border-none text-3xl" aria-label="Close">
            &times;
          </Dialog.Close>
        </div>
        <div className="space-y-2">
          <div>
            <Paragraph className="font-bold">
              {courseItem.SUBJECT} - {courseItem.CourseNbr} - {courseItem.DESCR}{" "}
              - {courseItem.CREDITS}
            </Paragraph>
            <Paragraph>{courseItem.STRM}</Paragraph>
          </div>
          <div>
            <Paragraph className="font-bold">Grading Basis</Paragraph>
            <Paragraph>Graded</Paragraph>
          </div>
          <div>
            <Paragraph className="font-bold">Course Components</Paragraph>
            <Paragraph>{courseItem.Class[0]?.COMPONENT_DESC}</Paragraph>
          </div>
          <div>
            <Paragraph className="font-bold">Course Description</Paragraph>
            <Paragraph>{courseItem.DESRLONG}</Paragraph>
          </div>
          <div>
            <Paragraph className="font-bold">
              Enrollment Information: {courseItem.SUBJECT} -{" "}
              {courseItem.CourseNbr} - {courseItem.COURSE_TITLE_LONG}
            </Paragraph>
          </div>
        </div>
        <div className="mt-[30px] flex flex-row justify-end gap-2 border-0 border-t border-solid border-du-gray pt-[30px]">
          <Dialog.Close className="border border-solid border-du-yellow bg-du-yellow px-4 py-2 font-bold">
            Close
          </Dialog.Close>
        </div>
      </ModalWrapper>
    </Dialog.Root>
  );
};

export default CourseDetails;

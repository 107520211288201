import React from "react";

const Comment: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M 12 3 C 17.789062 3 22.5 6.703125 22.5 11.25 C 22.5 15.796875 17.789062 19.5 12 19.5 C 10.671875 19.5 9.359375 19.296875 8.101562 18.898438 L 7.386719 18.675781 L 6.777344 19.105469 C 5.699219 19.871094 4.035156 20.761719 1.96875 20.960938 C 2.53125 20.253906 3.367188 19.070312 3.882812 17.699219 L 4.214844 16.824219 L 3.570312 16.140625 C 2.21875 14.703125 1.5 13.011719 1.5 11.25 C 1.5 6.703125 6.210938 3 12 3 M 12 1.5 C 5.371094 1.5 0 5.863281 0 11.25 C 0 13.480469 0.933594 15.523438 2.480469 17.171875 C 1.78125 19.015625 0.328125 20.582031 0.304688 20.601562 C -0.00390625 20.929688 -0.0898438 21.40625 0.0898438 21.820312 C 0.265625 22.234375 0.671875 22.5 1.121094 22.5 C 4.003906 22.5 6.277344 21.296875 7.640625 20.328125 C 9 20.757812 10.460938 21 12 21 C 18.628906 21 24 16.636719 24 11.25 C 24 5.863281 18.628906 1.5 12 1.5 Z M 12 1.5 "
        fill="#3c3c43"
      />
    </svg>
  );
};

export default Comment;

export const campusDisplay: Record<string, string> = {
  ADLPH: "UMGC Stateside",
  EUROP: "UMGC Europe",
  ASIA: "UMGC Asia",
};

export const careerDisplay: Record<string, string> = {
  UGRD: "Undergrad",
  GRAD: "Grad",
  GSAL: "GSAL",
  GR4T: "GR4T",
  USAL: "USAL",
};

export const careerOrder: Record<string, number> = {
  UGRD: 1,
  GRAD: 2,
  GR4T: 3,
  USAL: 4,
  GSAL: 5,
};

import { RefObject } from "react";
import { useEventListener } from "usehooks-ts";

type Handler = (event: MouseEvent) => void;

export function useOnClickOutsideMany<T extends HTMLElement = HTMLElement>(
  refs: RefObject<T>[],
  handler: Handler,
  mouseEvent: "mousedown" | "mouseup" = "mousedown"
): void {
  useEventListener(mouseEvent, (event) => {
    // const el = ref?.current;
    const els = refs.map((ref) => ref?.current);

    // Do nothing if clicking ref's element or descendent elements
    for (let i = 0; i < els.length; i++) {
      const el = els[i];
      if (!el || el.contains(event.target as Node)) {
        return;
      }
    }

    handler(event);
  });
}

import "./financialAid.css";

import React, { useEffect, useState } from "react";
import { angularize } from "react-in-angularjs";
import getDuService from "ServiceBroker";
import { financialAidTOdos } from "types/financialAidTodo";
import { TodosResponse } from "types/TodosResponse";

const FinToDo = () => {
  const [financialAidTodos, setFinancialAidTodos] = useState<
    financialAidTOdos[]
  >([]);
  const [todoCount, SettodoCount] = useState<number>(0);
  const [errorMsg, setErrorMsg] = useState<string>(
    "You have no Financial Aid To Dos"
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);
  const getNumberOfItemsToDisplay = () => {
    return screenWidth > 768 ? 7 : 3;
  };

  const handleClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    todo: financialAidTOdos
  ) => {
    event.preventDefault();
    sessionStorage.setItem("todoList", JSON.stringify(todo));
    window.location.href = "#/fintoDoInfo/index=0";
  };

  const todoList: financialAidTOdos[] = [];

  const getTodosInfo = async () => {
    setLoading(true);
    try {
      const todoInfo = (await getDuService({
        service: "todoService",
        method: "getTodos",
      })) as TodosResponse;
      const itemList = (await todoInfo.UC_DU_TO_DO_RESP.UC_DU_TO_DO_ITEM) || [];

      itemList.forEach((item, index) => {
        if (
          (item.ADMIN_FUNC === "Financial Aid" ||
            item.ADMIN_FUNC == "Financial Aid Term") &&
          item.STATUS === "Initiated"
        ) {
          const YEAR = item.DUE_DATE.split("-")[0]; // Extract the year from DUE_DATE

          todoList.push({
            INDEX: index,
            TO_DO_ITEM: item.TO_DO_ITEM,
            DUE_DATE: item.DUE_DATE,
            DUE_DATE_FORMATTED: getDateFormat(item.DUE_DATE),
            STATUS: item.STATUS,
            YEAR: YEAR,
            ADMIN_FUNC: item.ADMIN_FUNC,
            CONTACT: item.UC_DU_TO_DO_DOC.CONTACT,
            DESCRIPTION: item.UC_DU_TO_DO_DOC.DESCRIPTION,
          });
        }
      });

      if (todoList.length > 0) {
        let sortedTodos = todoList.sort(
          (a, b) =>
            new Date(b.DUE_DATE).getTime() - new Date(a.DUE_DATE).getTime()
        );
        sortedTodos = sortedTodos
          .slice(0, getNumberOfItemsToDisplay())
          .sort(
            (a, b) =>
              new Date(a.DUE_DATE).getTime() - new Date(b.DUE_DATE).getTime()
          );

        setFinancialAidTodos(sortedTodos);
        sessionStorage.setItem("FinancialTodos", JSON.stringify(sortedTodos));
      } else {
        console.log("No Todos");
      }
    } catch (error) {
      setErrorMsg(
        "This information is temporarily unavailable. We apologize for any inconvenience and are working to correct the issue. Please try again later."
      );
      console.error("Error fetching To-Dos:", error);
    } finally {
      SettodoCount(todoList.length);
      setLoading(false);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    getTodosInfo();
  }, [screenWidth]);

  const getDateFormat = (date: string) => {
    const nDate = new Date(date);

    const month = (nDate.getMonth() + 1).toString().padStart(2, "0");

    const day = nDate.getUTCDate().toString().padStart(2, "0");

    const year = (nDate.getFullYear() % 100).toString().padStart(2, "0");

    const formattedDate = `${month}/${day}/${year}`;

    return formattedDate;
  };

  // Group To-Dos by the extracted YEAR
  const groupedTodos = financialAidTodos.reduce((grouped, todo) => {
    if (!grouped[todo.YEAR]) {
      grouped[todo.YEAR] = [];
    }
    grouped[todo.YEAR].push(todo);
    return grouped;
  }, {} as { [key: string]: financialAidTOdos[] });

  return (
    <div>
      <section
        aria-labelledby="Scholarships"
        id=""
        className="border-box resources-cls -webkit-shadow-[5px 5px 5px 0px rgba(0,0,0,0.1)]
       moz-shadow-[5px_5px_5px_0px_rgba(255,255,0,1)]  
       relative mb-[1.25rem] mr-[0.05rem] min-h-[280px] bg-[#ffffff]"
        style={{
          boxShadow: "5px 5px 5px 0px rgba(0,0,0,0.1)",
          marginLeft: "1.65rem",
          padding: "1.25rem 1.25rem 0",
        }}
      >
        <div className="flex-justify-between">
          <div className="text-left">
            <h3
              className="text-[1.5em]"
              style={{
                fontFamily: "'Roboto', Tahoma, sans-serif",
                fontWeight: "300",
                lineHeight: "1.4em",
                borderBottom: "0.5px solid #ccc",
                paddingBottom: "1rem",
              }}
            >
              <img
                src="https://portalstg.umgc.edu/images/du-todo-icon.svg"
                alt="icon"
                style={{
                  width: "25px",
                  height: "25px",
                  display: "inline-flex",
                }}
              />{" "}
              Financial Aid To Dos {todoCount > 0 && !loading ? todoCount : ""}
            </h3>
          </div>
        </div>
        {todoCount > 0 ? (
          <div>
            <div
              className="flex-justify-between"
              style={{
                paddingBottom: "1rem",
                borderBottom: "0.5px solid #ccc",
              }}
            >
              <div>
                <section className="du-fintodo-content-section">
                  <table
                    id="du-todo-table"
                    className="du-table du-table-with-alternating-rows du-table-left-align"
                  >
                    <thead>
                      <tr>
                        <th className="du-no-bottom-padding du-no-top-padding">
                          <span className="du-strong-text-title">Name</span>
                        </th>
                        <th className="du-no-bottom-padding du-no-top-padding">
                          <span className="du-strong-text-title">Due Date</span>
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {Object.keys(groupedTodos)
                        .sort((a: string, b: string) => {
                          return Number(b) - Number(a);
                        })
                        .map((year) => (
                          <div key={year} className="contents">
                            <h4 className="mb-2 mt-2 text-[0.75rem]">
                              {year} - {Number(year) + 1}
                            </h4>
                            {groupedTodos[year].map((todo, index) => (
                              <tr key={index}>
                                <td>
                                  <a
                                    href="#"
                                    title={todo.TO_DO_ITEM}
                                    onClick={(event) =>
                                      handleClick(event, todo)
                                    }
                                  >
                                    {todo.TO_DO_ITEM}
                                  </a>
                                </td>
                                <td>
                                  <span className="text-[0.75rem]">
                                    {todo.DUE_DATE_FORMATTED}
                                  </span>
                                </td>
                              </tr>
                            ))}
                          </div>
                        ))}
                    </tbody>
                  </table>
                </section>
              </div>
            </div>
            <div className="finTodo">
              <a href="#/finTodoDetails" title="">
                View All Financial Aid To Dos
              </a>
            </div>
          </div>
        ) : (
          <p className="mt-2 text-[0.75rem]">{!loading ? errorMsg : ""}</p>
        )}
      </section>
      <div></div>
    </div>
  );
};

angularize(FinToDo, "finToDoReact", angular.module("duApp"), {});
export default FinToDo;

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState } from "react";

export default function DragComp(props: any) {
  const {
    onPointerDown,
    onPointerUp,
    onPointerMove,
    onDragMove,
    children,
    style,
    className,
    onDragChange,
  } = props;

  const [isDragging, setIsDragging] = useState(false);

  const handlePointerDown = (e: any) => {
    setIsDragging(true);
    onDragChange(isDragging);
    onPointerDown(e);
  };

  const handlePointerUp = (e: any) => {
    setIsDragging(false);
    onDragChange(isDragging);
    onPointerUp(e);
  };

  const handlePointerLeave = (e: any) => {
    setTimeout(() => {
      setIsDragging(false);
      onPointerUp(e);
    }, 5000);
  };

  const handlePointerMove = (e: any) => {
    if (isDragging) {
      onDragChange(isDragging);
      onDragMove(e);
    }
    onPointerMove(e);
  };

  return (
    <div
      onPointerDown={handlePointerDown}
      onPointerUp={handlePointerUp}
      onPointerMove={handlePointerMove}
      onPointerLeave={handlePointerLeave}
      style={{
        cursor: isDragging ? "grab" : "pointer",
        position: "fixed",
        bottom: "20px",
        right: "20px",
        ...style,
      }}
      className={className}
    >
      {children}
    </div>
  );
}

DragComp.defaultProps = {
  onPointerDown: () => {},
  onPointerUp: () => {},
  onPointerMove: () => {},
};

import { ClassSearchContext } from "pages/newClassSearch/classSearchContext";
import { resetAllEventChannel } from "pages/newClassSearch/events/resetAllEventMsg";
import React, { useContext, useEffect, useState } from "react";

const DEBOUNCE_DELAY_TIME = 800;

const SearchCatalogField = () => {
  const {
    dispatch,
    state: { freeSearch },
    classSearchState: {
      classFirstLoad,
      setClassFirstLoad,
      setClassPagination,
      handleSearch,
    },
  } = useContext(ClassSearchContext);

  const [inputValue, setInputValue] = useState("");
  const [debouncedInputValue, setDebouncedInputValue] = useState("");

  useEffect(() => {
    const delayInputTimeoutId = setTimeout(() => {
      setDebouncedInputValue(inputValue);
    }, DEBOUNCE_DELAY_TIME);
    return () => clearTimeout(delayInputTimeoutId);
  }, [inputValue]);

  useEffect(() => {
    dispatch({
      type: "UPDATE_FREE_SEARCH",
      payload: {
        freeSearch: debouncedInputValue,
      },
    });
  }, [debouncedInputValue]);

  useEffect(() => {
    if (!classFirstLoad) setClassFirstLoad(true);
    if (debouncedInputValue && debouncedInputValue !== "") {
      handleSearch(10, 0);
      setClassPagination({ top: 10, skip: 0 });
    }
  }, [freeSearch]);

  useEffect(() => {
    const unsubscribeOnResetAll = resetAllEventChannel.on("onResetAll", () => {
      setInputValue("");
      setDebouncedInputValue("");
    });

    return () => {
      unsubscribeOnResetAll();
    };
  }, []);

  return (
    <div className="space-y-1">
      <label htmlFor="cs-search-catalog-input" className="font-bold">
        Search Catalog
      </label>
      <div className="relative">
        <input
          id="cs-search-catalog-input"
          className="w-full rounded-sm border px-1 text-base placeholder:text-[10.5px]"
          value={inputValue}
          onChange={(e) => {
            const newValue = e.target.value;
            setInputValue(newValue);
          }}
          placeholder="Search class number, description, etc."
        />
        {inputValue && (
          <button
            className="absolute right-[4px] top-[3px] h-[20px] w-[20px] rounded-full bg-[#3C3C43] text-white"
            onClick={() => {
              setInputValue("");
              setDebouncedInputValue("");
            }}
          >
            &times;
          </button>
        )}
      </div>
    </div>
  );
};

export default SearchCatalogField;

import React from "react";

interface TileProps {
  children?: React.ReactNode;
  className?: string;
  ariaLabel?: string;
}

const Tile: React.FC<TileProps> = ({ children, className, ariaLabel }) => {
  return (
    <div
      className={`block bg-white p-6 sm:shadow-lg ${className ?? ""}`}
      aria-label={ariaLabel}
    >
      {children}
    </div>
  );
};

export default Tile;

import clsx from "clsx";
import Switch from "components/Switch";
import GraduationCap from "icons/GraduationCap";
import School from "icons/School";
import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import { ClassSearchContext } from "../classSearchContext";
import { campusDisplay, careerDisplay } from "../display";
import CampusField from "./fields/CampusField";
import CareerField from "./fields/CareerField";

const Personalize = () => {
  const [isPersonalizeEnabled, setIsPersonalizeEnabled] = useState(true);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const timeOut = useRef<number | null>(null);
  const tooltipRef = useRef<HTMLDivElement | null>(null);

  const {
    state: {
      profile: { campusCode, academicCareerCode, academicCareerCodeList },
    },
    dispatch,
  } = useContext(ClassSearchContext);

  const onMouseLeave = () => {
    timeOut.current = window.setTimeout(() => {
      setIsTooltipVisible(false);
    }, 100);
  };

  const onMouseEnter = () => {
    if (timeOut.current !== null) {
      window.clearTimeout(timeOut.current);
      timeOut.current = null;
    }
    setIsTooltipVisible(true);
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      setIsTooltipVisible(false);
    }
  };

  useEffect(() => {
    if (isPersonalizeEnabled) {
      dispatch({
        type: "UPDATE_CAMPUS_CODE",
        payload: {
          campusCode,
        },
      });
      dispatch({
        type: "UPDATE_ACADEMIC_CAREER_CODE",
        payload: {
          academicCareerCode,
        },
      });
    }
  }, [isPersonalizeEnabled]);

  useEffect(() => {
    if (isTooltipVisible) {
      document.addEventListener("keydown", handleKeyDown);
    } else {
      document.removeEventListener("keydown", handleKeyDown);
    }
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isTooltipVisible]);

  return (
    <div>
      <div className="flex items-center justify-between rounded-sm border bg-[#fafafa] p-2">
        <div>
          <p className="font-bold">Personalize</p>
          <div className="relative">
            <button
              className="text-xs font-bold text-du-skyblue underline"
              onFocus={() => setIsTooltipVisible(true)}
              onBlur={() => setIsTooltipVisible(false)}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
              aria-describedby="tooltip-description"
            >
              What is this?
            </button>
            <div
              ref={tooltipRef}
              className={`absolute mt-[10px] w-[200px] space-y-2 rounded bg-du-black px-2 py-2 text-sm shadow-lg ${
                isTooltipVisible ? "visible z-50" : "invisible"
              }`}
              id="tooltip-description"
              role="tooltip"
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
            >
              <p className="text-sm text-white">
                Search results are personalized to enhance your experience.
              </p>
              <div className="space-y-2">
                {campusDisplay[campusCode] && (
                  <p className="flex items-center gap-2">
                    <School className="h-5 w-5" />
                    <span className="text-sm font-bold text-white">
                      {campusDisplay[campusCode]}
                    </span>
                  </p>
                )}
                {careerDisplay[academicCareerCode] && (
                  <p className="flex items-center gap-2">
                    <GraduationCap className="h-5 w-5" />
                    <span className="text-sm font-bold text-white">
                      {academicCareerCodeList.length > 1
                        ? academicCareerCodeList
                            .map((code) => careerDisplay[code])
                            .join(" - ")
                        : careerDisplay[academicCareerCode]}
                    </span>
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
        <div>
          <Switch
            id="cs-personalize-switch"
            value={isPersonalizeEnabled}
            onChange={(value) => {
              setIsPersonalizeEnabled(value);
            }}
          />
        </div>
      </div>

      <div className={clsx(isPersonalizeEnabled && "hidden")}>
        <CampusField />
        <CareerField />
      </div>
    </div>
  );
};

export default Personalize;

import React from "react";

interface ChevronUpProps extends React.SVGProps<SVGSVGElement> {
  fillColor?: string;
}

const ChevronUp: React.FC<ChevronUpProps> = ({ fillColor, ...props }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 448 512"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M207.029 130.524L12.686 324.971c-4.686 4.686-4.686 12.284 0 16.971l28.284 28.284c4.686 4.686 12.284 4.686 16.971 0L224 218.059l166.059 151.167c4.686 4.686 12.284 4.686 16.971 0l28.284-28.284c4.686-4.686 4.686-12.284 0-16.971L240.971 130.524c-4.686-4.686-12.284-4.686-16.971 0z"
        fill={fillColor ? fillColor : "#3c3c43"}
      ></path>
    </svg>
  );
};

export default ChevronUp;

import clsx from "clsx";
import Alert from "components/Alert";
import Link from "components/Link";
import Pagination from "components/Pagination";
import Paragraph from "components/Paragraph";
import CaretDown from "icons/CaretDown";
import CaretRight from "icons/CaretRight";
import React, { Fragment, useEffect, useState } from "react";
import getDuService from "ServiceBroker";
import { GradingEnum } from "types/editGradingModal";

import ClassTableDesktop from "./ClassTableDesktop";
import ClassTableMobile from "./ClassTableMobile";
import { ClassUI, CourseUI, Params } from "./Course";
import AddToClassError from "./modals/AddToClassError";
import CourseDetails from "./modals/CourseDetails";

interface CourseTableProps {
  courseList: CourseUI[];
  setCourseList: React.Dispatch<React.SetStateAction<CourseUI[]>>;
  params: Params;
  refreshTable: () => void;
}

const CourseTable: React.FC<CourseTableProps> = ({
  courseList,
  setCourseList,
  params: { emplid, institution, strm, acadCareer, impersonate },
  refreshTable,
}) => {
  const { PaginationComponent, currentPage, setCurrentPage, pageSize } =
    Pagination({
      count: courseList.length,
      pageSize: 10,
    });

  const [itemsFrom, setItemsFrom] = useState(0);
  const [itemsTo, setItemsTo] = useState(0);
  const [gotToPage, setGoToPage] = useState(0);

  const [addToClassModal, setAddToClassModal] = useState<{
    content: React.ReactNode;
    isOpen: boolean;
  }>({
    content: "",
    isOpen: false,
  });

  useEffect(() => {
    setItemsFrom(1 + pageSize * (currentPage - 1));
    setItemsTo(Math.min(10 + pageSize * (currentPage - 1), courseList.length));
  }, [pageSize, currentPage, courseList]);

  useEffect(() => {
    setCurrentPage(1);
  }, [emplid, institution, strm, acadCareer]);

  const handleToggle = (course: CourseUI, prop: "open" | "showMenu") => {
    setCourseList((prev) => {
      prev = prev?.map((c) => {
        if (c.CourseNbr === course.CourseNbr && c.SUBJECT === course.SUBJECT) {
          c[prop] = !c[prop];
        } else {
          c[prop] = false;
        }
        return c;
      });
      return prev;
    });
  };

  const setPlannerStatusToLoading = (course: CourseUI) => {
    setCourseList((prev) => {
      prev = prev?.map((c) => {
        if (c.CourseNbr === course.CourseNbr && c.SUBJECT === course.SUBJECT) {
          c.plannerStatus = "loading";
        }
        return c;
      });
      return prev;
    });
  };

  const handleAddCourseToPlanner = async (
    courseItem: CourseUI,
    emplid?: string
  ) => {
    if (impersonate === "true") return;
    // uncomment line below if user's academic career will be used
    // const acadCareer = courseItem.Class[0].ACAD_CAREEER;
    const campusDesc = courseItem.Class[0].CAMPUS_DESC;
    // map campus desc to campus code
    const courseCampusCode: { [key: string]: string } = {
      "UMGC Adelphi": "ADLPH",
      "UMGC Asia": "ASIA",
      "UMGC Europe": "EUROP",
    };

    const payload = {
      UC_MYPLAN_ADCRS_REQ: {
        EMPLID: emplid,
        ACAD_CAREER: acadCareer,
        CRSE_ID: "",
        STRM: courseItem.STRM,
        SUBJECT: courseItem.SUBJECT,
        CATALOG_NBR: courseItem.CourseNbr,
        CAMPUS: courseCampusCode[campusDesc],
        REQUIREMENT: "",
        RQ_LINE_NBR: "",
      },
    };
    setPlannerStatusToLoading(courseItem);
    try {
      const result = await getDuService(
        {
          service: "classSearchService",
          method: "putAddToPlanner",
        },
        payload
      );
    } catch (error) {
      // send event to angular
      window.dispatchEvent(
        new CustomEvent("showAddToPlannerErrorEvent", {
          detail: {
            response: error,
          },
        })
      );
    }
    refreshTable();
  };

  const handleRemoveCourseFromPlanner = async (
    courseItem: CourseUI,
    emplid?: string
  ) => {
    if (impersonate === "true") return;
    // uncomment line below if user's academic career will be used
    // const acadCareer = courseItem.Class[0].ACAD_CAREEER;
    const payload = {
      UC_MYPLN_DELCRS_REQ: {
        UC_MYPLN_CRSE_LIST: [
          {
            SUBJECT: courseItem.SUBJECT,
            CATALOG_NBR: courseItem.CourseNbr,
          },
        ],
        EMPLID: emplid,
        ACAD_CAREER: acadCareer,
        STRM: courseItem.STRM,
      },
    };
    setPlannerStatusToLoading(courseItem);
    const result = await getDuService(
      {
        service: "classSearchService",
        method: "deleteFromPlanner",
      },
      payload
    );
    refreshTable();
  };

  const handleAddClassToCart = async (
    courseItem: CourseUI,
    classItem: ClassUI,
    emplid: string,
    institution: string,
    strm: string,
    acadCareer: string,
    grading?: GradingEnum
  ) => {
    if (impersonate === "true") return;
    const payload = {
      ShoppingCartReqst: {
        Emplid: emplid,
        Institution: institution,
        AcadCareer: acadCareer,
        Term: strm,
        ClassNumber: classItem.CLASS_NBR,
        ...(grading ? { GradingBasis: grading } : {}),
      },
    };
    try {
      const result = await getDuService(
        {
          service: "classSearchService",
          method: "putShoppingCart",
        },
        payload
      );

      // send event to angular
      window.dispatchEvent(
        new CustomEvent("addToShoppingCartEvent", {
          detail: {
            courseItem,
            classItem,
          },
        })
      );
    } catch (error) {
      const errorCode = (error as any)?.status;
      const errorMsg = (error as any)?.data?.ShoppingCartPUTResp
        ?.RespMsg as string;
      if (
        errorCode === 400 &&
        errorMsg.includes("The last day to enroll has passed")
      ) {
        setAddToClassModal({
          isOpen: true,
          content: (
            <Paragraph className="flex gap-3 font-bold">
              <Alert />
              <span>
                This class cannot be added to your cart because the enrollment
                deadline has passed. Return to Class Search if you wish to find
                a later session.
              </span>
            </Paragraph>
          ),
        });
      } else if (errorCode >= 400 && errorCode < 500) {
        setAddToClassModal({
          isOpen: true,
          content: (
            <Paragraph className="flex gap-3 font-bold">
              <Alert />
              <span>
                This class cannot be added to your cart. If the problem
                persists,
                <Link href="http://www.umgc.edu/current-students/student-life-and-support/advising.cfm">
                  contact an advisor
                </Link>
                .
              </span>
            </Paragraph>
          ),
        });
      } else if (errorCode >= 500) {
        setAddToClassModal({
          isOpen: true,
          content: (
            <Paragraph className="flex gap-3 font-bold">
              <Alert />
              <span>
                We are unable to complete your request at this time. We
                apologize for any inconvenience and are working to correct the
                issue. Please try again later.
              </span>
            </Paragraph>
          ),
        });
      }
      return;
    }
    refreshTable();
  };

  const handleRemoveClassFromCart = async (
    classItem: ClassUI,
    strm: string,
    acadCareer: string
  ) => {
    if (impersonate === "true") return;
    const deleteUrl = `${strm}/${acadCareer}?classNumber=${classItem.CLASS_NBR}`;
    const result = await getDuService(
      {
        service: "classSearchService",
        method: "deleteShoppingCart",
      },
      deleteUrl
    );
    // send event to angular
    window.scrollTo(0, 0);
    window.dispatchEvent(new CustomEvent("updateCartCountEvent"));
    refreshTable();
  };

  if (!courseList) return null;
  return (
    <div className="space-y-5">
      <p className="font-secondary text-2xl font-thin">
        {itemsFrom} - {itemsTo} of {courseList.length} result
        {courseList.length > 1 && "s"}
      </p>
      {courseList.slice(itemsFrom - 1, itemsTo).map((courseItem, index) => (
        <Fragment key={`${courseItem.CourseNbr}-${index}`}>
          <div className="flex items-center justify-between gap-2 bg-du-gray p-[14px]">
            <div
              className="flex w-[60%] cursor-pointer items-center gap-2"
              onClick={() => {
                handleToggle(courseItem, "open");
              }}
            >
              {courseItem.open ? (
                <CaretDown className="h-4 w-4" />
              ) : (
                <CaretRight className="h-4 w-4" />
              )}
              <p className="inline text-[15px] text-du-black">
                {courseItem.SUBJECT} - {courseItem.subjectDesc} -{" "}
                {courseItem.CourseNbr} - {courseItem.COURSE_TITLE_LONG} -{" "}
                {courseItem.CREDITS}
              </p>
            </div>
            <div className="flex w-[40%] justify-end du-sm:hidden">
              <div className="relative">
                {courseItem.showMenu && (
                  <ul className="absolute -left-[145px] -top-[25px] w-[130px] bg-white shadow-lg">
                    <CourseDetails
                      trigger={
                        <li
                          role="button"
                          className="cursor-pointer p-2 text-du-blue hover:bg-du-light-gray"
                        >
                          Course Details
                        </li>
                      }
                      courseItem={courseItem}
                    />
                    {(() => {
                      switch (courseItem.plannerStatus) {
                        case "add":
                          return (
                            <li
                              role="button"
                              className={clsx(
                                "p-2 text-du-blue hover:bg-du-light-gray",
                                impersonate === "true"
                                  ? "cursor-not-allowed"
                                  : "cursor-pointer"
                              )}
                              onClick={() => {
                                handleAddCourseToPlanner(courseItem, emplid);
                              }}
                            >
                              Add to Planner
                            </li>
                          );
                        case "remove":
                          return (
                            <li
                              role="button"
                              className={clsx(
                                "p-2 text-du-blue hover:bg-du-light-gray",
                                impersonate === "true"
                                  ? "cursor-not-allowed"
                                  : "cursor-pointer"
                              )}
                              onClick={() => {
                                handleRemoveCourseFromPlanner(
                                  courseItem,
                                  emplid
                                );
                              }}
                            >
                              Remove from Planner
                            </li>
                          );
                        case "loading":
                          return (
                            <li
                              role="button"
                              className="cursor-pointer p-2 text-du-blue hover:bg-du-light-gray"
                            >
                              Loading...
                            </li>
                          );
                      }
                    })()}
                  </ul>
                )}
                <button
                  className="mr-2 border-0 after:inline-block after:font-awesome after:text-xl after:font-light after:text-du-black after:content-['\f142']"
                  onClick={() => {
                    handleToggle(courseItem, "showMenu");
                  }}
                ></button>
              </div>
            </div>
            <div className="hidden w-[40%] justify-around gap-1 text-xs font-bold du-sm:flex">
              <button
                className="cursor-pointer border-none text-left text-du-blue hover:underline"
                onClick={() => {
                  handleToggle(courseItem, "open");
                }}
              >
                {courseItem.open ? "Hide" : "Show"} Sections
              </button>
              <CourseDetails
                trigger={
                  <button className="cursor-pointer border-none text-left text-du-blue hover:underline">
                    Course Details
                  </button>
                }
                courseItem={courseItem}
              />
              {(() => {
                switch (courseItem.plannerStatus) {
                  case "add":
                    return (
                      <button
                        className={clsx(
                          "border-none text-left text-du-blue hover:underline",
                          impersonate === "true"
                            ? "cursor-not-allowed"
                            : "cursor-pointer"
                        )}
                        onClick={() => {
                          handleAddCourseToPlanner(courseItem, emplid);
                        }}
                      >
                        Add to Planner
                      </button>
                    );
                  case "remove":
                    return (
                      <button
                        className={clsx(
                          "border-none text-left text-du-blue hover:underline",
                          impersonate === "true"
                            ? "cursor-not-allowed"
                            : "cursor-pointer"
                        )}
                        onClick={() => {
                          handleRemoveCourseFromPlanner(courseItem, emplid);
                        }}
                      >
                        Remove from Planner
                      </button>
                    );
                  case "loading":
                    return (
                      <button className="cursor-pointer border-none text-left text-du-blue hover:underline">
                        Loading...
                      </button>
                    );
                }
              })()}
            </div>
          </div>
          {courseItem.open && (
            <div className="!mt-0 border-[1px] border-solid border-du-light-gray pt-4">
              <ClassTableDesktop
                career={acadCareer}
                classList={courseItem.Class}
                handleAddClassToCart={(classItem, grading) => {
                  handleAddClassToCart(
                    courseItem,
                    classItem,
                    emplid,
                    institution,
                    strm,
                    acadCareer,
                    grading
                  );
                }}
                handleRemoveClassFromCart={(classItem) => {
                  handleRemoveClassFromCart(classItem, strm, acadCareer);
                }}
                impersonate={impersonate}
              />
              <ClassTableMobile
                career={acadCareer}
                classList={courseItem.Class}
                handleAddClassToCart={(classItem, grading) => {
                  handleAddClassToCart(
                    courseItem,
                    classItem,
                    emplid,
                    institution,
                    strm,
                    acadCareer,
                    grading
                  );
                }}
                handleRemoveClassFromCart={(classItem) => {
                  handleRemoveClassFromCart(classItem, strm, acadCareer);
                }}
                impersonate={impersonate}
              />
            </div>
          )}
        </Fragment>
      ))}
      <PaginationComponent />
      <div className="flex items-center gap-2">
        <span className="font-bold">Go to page</span>
        <input
          className="all-revert w-10 p-2"
          type="number"
          value={gotToPage}
          onChange={(e) => {
            setGoToPage(e.target.valueAsNumber);
          }}
        />
        <button
          className="border border-solid border-du-yellow bg-du-yellow px-4 py-2 font-bold"
          onClick={() => {
            setCurrentPage(gotToPage);
          }}
        >
          Go
        </button>
      </div>
      <AddToClassError
        open={addToClassModal.isOpen}
        content={addToClassModal.content}
        action={(open) => {
          setAddToClassModal((prev) => ({ ...prev, isOpen: open }));
        }}
      />
    </div>
  );
};

export default CourseTable;

import clsx from "clsx";
import ConfirmModal from "components/ConfirmModal";
import ErrorModal from "components/ErrorModal";
import Pagination from "components/Pagination";
import { getShoppingCartResponse } from "pages/classSearch/course/resources";
import { ClassSearchContext } from "pages/newClassSearch/classSearchContext";
import {
  EField,
  fetchValues,
  IField,
  IOR,
  IQuery,
} from "pages/newClassSearch/classSearchQuery";
import {
  getFieldList,
  getFilter,
} from "pages/newClassSearch/classSearchReducer";
import {
  ClassUI,
  SectionItem as SectionItemType,
  SectionResponse,
  SectionUI,
} from "pages/newClassSearch/types";
import React, { ReactNode, useContext, useEffect, useState } from "react";
import getDuService from "ServiceBroker";
import { CartDetail } from "types/classSearchService";

import SectionItem from "./SectionItem";

export const SECTION_PAGE_SIZE = 10;

interface SectionListProps {
  classItem: ClassUI;
}

const SectionList: React.FC<SectionListProps> = ({ classItem }) => {
  const {
    state: {
      profile: {
        emplid,
        academicCareerCode,
        academicCareerCodeList,
        campusCode,
      },
      fields,
      freeSearch,
    },
  } = useContext(ClassSearchContext);

  const isCrossCampus = fields.campusCode !== campusCode;

  const [sectionResponse, setSectionResponse] = useState<SectionResponse>({
    "@odata.context": "",
    "@odata.count": 0,
    "@odata.nextLink": "",
    value: [],
  });

  const { PaginationComponent, currentPage, setCurrentPage } = Pagination({
    count: sectionResponse["@odata.count"],
    pageSize: SECTION_PAGE_SIZE,
  });

  const [errorModal, setErrorModal] = useState<{
    open: boolean;
    content: ReactNode;
  }>({
    open: false,
    content: <div />,
  });

  const [confirmationModal, setConfirmationModal] = useState<{
    open: boolean;
    currSectionItem?: SectionUI;
  }>({
    open: false,
    currSectionItem: undefined,
  });

  const handleAddSectionToCart = async (
    classItem: ClassUI,
    sectionItem: SectionItemType
  ) => {
    const payload = {
      ShoppingCartReqst: {
        Emplid: emplid,
        Institution: "UCALL",
        AcadCareer: academicCareerCode,
        Term: sectionItem.semesterCode,
        ClassNumber: sectionItem.classNumber,
      },
    };

    try {
      const result = await getDuService(
        {
          service: "classSearchService",
          method: "putShoppingCart",
        },
        payload
      );
      // send event to angular
      window.dispatchEvent(
        new CustomEvent("addToShoppingCartEvent", {
          detail: {
            courseItem: {
              SUBJECT: classItem.subjectCode,
              CourseNbr: classItem.catalogNumber,
              COURSE_TITLE_LONG: classItem.courseTitle,
              CREDITS: classItem.creditHours,
            },
            classItem: {
              CAMPUS_DESC: sectionItem.campusName,
              CLASS_NBR: sectionItem.classNumber,
              MEETING_SCHEDULE: sectionItem.classStartDate,
              MEETING_END_TIME: sectionItem.classEndDate,
              START_DT: sectionItem.classStartDate,
              END_DT: sectionItem.classEndDate,
            },
          },
        })
      );
    } catch (error) {
      setErrorModal({
        open: true,
        content: (
          <div>
            <h2 className="text-lg font-bold">Error</h2>
            <p className="text-sm">
              <span>
                This class cannot be added to your cart. If the problem
                persists,
                <a
                  className="text-[15px] font-bold text-du-blue hover:cursor-pointer hover:underline"
                  rel="noreferrer"
                  href="http://www.umgc.edu/current-students/student-life-and-support/advising.cfm"
                  target="_blank"
                >
                  contact an advisor
                </a>
                .
              </span>
            </p>
          </div>
        ),
      });
    }
  };

  const handleRemoveSectionFromCart = async (sectionItem: SectionUI) => {
    const careerCode = academicCareerCode;
    const deleteUrl = `${sectionItem.semesterCode}/${careerCode}?classNumber=${sectionItem.classNumber}`;
    const result = await getDuService(
      {
        service: "classSearchService",
        method: "deleteShoppingCart",
      },
      deleteUrl
    );
    // send event to angular
    window.scrollTo(0, 0);
    window.dispatchEvent(new CustomEvent("updateCartCountEvent"));
  };

  const fetchSections = async (classUI: ClassUI, top: number, skip: number) => {
    const fieldList: (IField | IOR)[] = getFieldList(
      fields,
      academicCareerCodeList
    );
    if (fieldList.length === 0) return;

    fieldList.push({
      subjectCode: {
        equals: classUI.subjectCode,
      },
    });

    fieldList.push({
      catalogNumber: {
        equals: classUI.catalogNumber,
      },
    });

    fieldList.push({
      semesterCode: {
        equals: classUI.semesterCode,
      },
    });

    const filter = getFilter(fields, fieldList);

    const query: IQuery = {
      select: [
        EField.campusCode,
        EField.campusName,
        EField.classSection,
        EField.semesterCode,
        EField.semesterName,
        EField.classNumber,
        EField.locationName,
        EField.enrollmentCapacity,
        EField.enrollmentTotal,
        EField.orgCode,
        EField.classStartDate,
        EField.classEndDate,
        EField.classFormatCode,
        EField.classFormatName,
        EField.faculty,
        EField.classStartTime,
        EField.classEndTime,
        EField.classMonday,
        EField.classTuesday,
        EField.classWednesday,
        EField.classThursday,
        EField.classFriday,
        EField.classSaturday,
        EField.classSunday,
        EField.classNotes,
      ],
      filter,
      orderby: {
        classNumber: "asc",
      },
      top,
      skip,
    };

    if (freeSearch) {
      query.search = freeSearch;
    }

    const classPagination = (await fetchValues(
      query,
      "class"
    )) as SectionResponse;

    classPagination.value = classPagination.value.map((item) => ({
      ...item,
      inCart: false,
    }));

    setSectionResponse(classPagination);
    return classPagination;
  };

  const fetchCartList = async (sectionResponse: SectionResponse) => {
    const semesterSet = new Set<string>();
    sectionResponse.value.map((sectionItem) => {
      semesterSet.add(sectionItem.semesterCode);
    });
    const cartList: CartDetail[] = [];
    for (const semester of semesterSet) {
      const cartSubList = await getShoppingCartResponse(
        semester,
        academicCareerCode
      );
      cartList.push(...cartSubList);
    }

    if (cartList.length > 0) {
      for (let i = 0; i < sectionResponse.value.length; i++) {
        const sectionItem = sectionResponse.value[i];
        sectionItem.inCart = false;
        const cartItem = cartList.find((item) => {
          return item.ClassNbr === Number(sectionItem.classNumber);
        });

        if (cartItem) {
          sectionItem.inCart = true;
        }
      }
    }

    // update section list props
    setSectionResponse((prev) => ({
      ...prev,
      value: sectionResponse.value,
    }));
  };

  const fetchAll = async () => {
    const sectionResponse = await fetchSections(
      classItem,
      SECTION_PAGE_SIZE,
      SECTION_PAGE_SIZE * (currentPage - 1)
    );
    if (!sectionResponse) return;
    await fetchCartList(sectionResponse);
  };

  useEffect(() => {
    if (classItem.isCollapsed) {
      setSectionResponse({
        "@odata.context": "",
        "@odata.count": 0,
        "@odata.nextLink": "",
        value: [],
      });
    } else {
      fetchAll();
    }
    setCurrentPage(1);
  }, [classItem.isCollapsed]);

  useEffect(() => {
    if (!classItem.isCollapsed) {
      fetchAll();
    }
  }, [currentPage]);

  return (
    <div className={clsx(classItem.isCollapsed ? "hidden" : "block")}>
      <ul className="space-y-3">
        {sectionResponse.value.map((sectionItem, idx) => (
          <SectionItem
            key={idx}
            sectionItem={sectionItem}
            freeSearch={freeSearch}
            handleAddSectionToCart={handleAddSectionToCart}
            setConfirmationModal={setConfirmationModal}
            fetchCartList={fetchCartList}
            sectionResponse={sectionResponse}
            classItem={classItem}
            isCrossCampus={isCrossCampus}
          />
        ))}
      </ul>
      <div
        className={clsx(sectionResponse["@odata.count"] > 10 ? "" : "hidden")}
      >
        <PaginationComponent />
      </div>
      <ErrorModal
        open={errorModal.open}
        content={errorModal.content}
        action={(open) => setErrorModal({ ...errorModal, open })}
      />
      <ConfirmModal
        title="Remove Class"
        content={
          <div>
            Are you sure you want to remove this class from your shopping cart?
            Doing so means that registering for through the shopping cart will
            not enroll you for this class.
          </div>
        }
        open={confirmationModal.open}
        setIsOpen={(open) =>
          setConfirmationModal({ ...confirmationModal, open })
        }
        action={async () => {
          if (confirmationModal.currSectionItem) {
            await handleRemoveSectionFromCart(
              confirmationModal.currSectionItem
            );
            fetchCartList(sectionResponse);
            setConfirmationModal({
              open: false,
              currSectionItem: undefined,
            });
          }
        }}
      />
    </div>
  );
};

export default SectionList;

import React from "react";

interface ChevronLeftProps extends React.SVGProps<SVGSVGElement> {
  fillColor?: string;
}

const ChevronLeft: React.FC<ChevronLeftProps> = ({ fillColor, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="1em"
      viewBox="0 0 320 512"
      fill="currentColor"
      stroke="currentColor"
      {...props}
    >
      <path
        d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"
        fill={fillColor ? fillColor : "#003e6a"}
      />
    </svg>
  );
};

export default ChevronLeft;

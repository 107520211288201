import * as Dialog from "@radix-ui/react-dialog";
import ModalWrapper from "components/ModalWrapper";
import React, { ReactNode } from "react";

import Header from "./Header";
import Paragraph from "./Paragraph";

interface ConfirmModalProps {
  title: string;
  content: ReactNode;
  open: boolean;
  setIsOpen: (value: boolean) => void;
  action: () => void;
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({
  title,
  content,
  open,
  setIsOpen,
  action,
}) => {
  return (
    <Dialog.Root open={open} onOpenChange={setIsOpen}>
      <ModalWrapper show={open} className="h-[280px] max-h-[280px] w-[55vw]">
        <div className="mb-6 flex justify-between">
          <Header>{title}</Header>
          <Dialog.Close className="border-none text-3xl" aria-label="Close">
            &times;
          </Dialog.Close>
        </div>
        <Paragraph>{content}</Paragraph>
        <div className="mt-[30px] flex flex-row justify-end gap-4 border-0 border-t border-solid border-du-gray pt-[30px]">
          <Dialog.Close className="border-0 px-4 py-2 font-bold text-du-blue">
            No
          </Dialog.Close>
          <button
            onClick={() => {
              action();
            }}
            className="border border-solid border-du-yellow bg-du-yellow px-4 py-2 font-bold"
          >
            OK
          </button>
        </div>
      </ModalWrapper>
    </Dialog.Root>
  );
};

export default ConfirmModal;

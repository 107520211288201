import clsx from "clsx";
import ExternalLink from "icons/ExternalLink";
import React from "react";

interface OutLinkProps {
  children: React.ReactNode;
  href: string;
  iconPos?: "before" | "after";
  className?: string;
  iconClassName?: string;
  iconFillColor?: string;
}

const OutLink: React.FC<OutLinkProps> = ({
  children,
  href,
  iconPos = "after",
  className,
  iconClassName,
  iconFillColor,
}) => {
  const linkWithBeforeIcon = (
    <div className="inline-flex items-center gap-1">
      <ExternalLink
        fillColor={iconFillColor ? iconFillColor : "#003e6a"}
        className={clsx("h-4 w-4", iconClassName && iconClassName)}
      />
      <a
        className={clsx(
          "text-[15px] font-bold text-du-blue hover:cursor-pointer hover:underline",
          className && className
        )}
        rel="noreferrer"
        href={href}
        target="_blank"
      >
        {children}
        <span className="sr-only">(opens in new tab)</span>
      </a>
    </div>
  );

  const linkWithAfterIcon = (
    <div className="inline-flex items-center gap-1">
      <a
        className={clsx(
          "text-[15px] font-bold text-du-blue hover:cursor-pointer hover:underline",
          className && className
        )}
        rel="noreferrer"
        href={href}
        target="_blank"
      >
        {children}
        <span className="sr-only">(opens in new tab)</span>
      </a>
      <ExternalLink
        fillColor={iconFillColor ? iconFillColor : "#003e6a"}
        className={clsx("h-4 w-4", iconClassName && iconClassName)}
      />
    </div>
  );

  if (iconPos === "before") return linkWithBeforeIcon;
  else if (iconPos === "after") return linkWithAfterIcon;
  return null;
};

export default OutLink;

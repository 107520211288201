import { StudentTermResponse } from "types/classSearchService";

import { careerOrder } from "./display";

export const getCareerList = (studentTermResponse: StudentTermResponse) => {
  const careerList: string[] = studentTermResponse.Response.StudentTerm.map(
    (item) => item.ACAD_CAREER
  );
  const careerListUnique = [...new Set(careerList)].sort(
    (a, b) => careerOrder[a] - careerOrder[b]
  );
  return careerListUnique;
};

import * as SliderPrimitive from "@radix-ui/react-slider";
import clsx from "clsx";
import { ClassSearchContext } from "pages/newClassSearch/classSearchContext";
import {
  MAX_CREDIT_HOURS,
  MIN_CREDIT_HOURS,
} from "pages/newClassSearch/contants";
import React, { useContext } from "react";

const CreditHoursField = () => {
  const {
    state: {
      fields: { creditHoursMin, creditHoursMax },
    },
    dispatch,
  } = useContext(ClassSearchContext);

  const getBoldText = (value: number, min: number, max: number) => {
    if (min === value || max === value) {
      return "font-bold";
    }
    return "";
  };

  return (
    <div className="space-y-1">
      <label htmlFor="cs-credit-hours" className="font-bold">
        Credit Hours
      </label>
      <div className="mb-1.5 box-border flex w-48 flex-row justify-between px-1.5 text-du-black">
        <span
          className={clsx(
            "text-[13px]",
            getBoldText(1, creditHoursMin, creditHoursMax)
          )}
        >
          1
        </span>
        <span
          className={clsx(
            "text-[13px]",
            getBoldText(2, creditHoursMin, creditHoursMax)
          )}
        >
          2
        </span>
        <span
          className={clsx(
            "text-[13px]",
            getBoldText(3, creditHoursMin, creditHoursMax)
          )}
        >
          3
        </span>
        <span
          className={clsx(
            "text-[13px]",
            getBoldText(4, creditHoursMin, creditHoursMax)
          )}
        >
          4
        </span>
        <span
          className={clsx(
            "text-[13px]",
            getBoldText(5, creditHoursMin, creditHoursMax)
          )}
        >
          5
        </span>
        <span
          className={clsx(
            "text-[13px]",
            getBoldText(6, creditHoursMin, creditHoursMax)
          )}
        >
          6
        </span>
      </div>
      <SliderPrimitive.Root
        name="cs-credit-hours"
        id="cs-credit-hours"
        onValueChange={(valueRange) => {
          dispatch({
            type: "UPDATE_CREDIT_HOURS",
            payload: {
              creditHoursMin: valueRange[0],
              creditHoursMax: valueRange[1],
            },
          });
        }}
        defaultValue={[1, 6]}
        value={[creditHoursMin, creditHoursMax]}
        min={MIN_CREDIT_HOURS}
        max={MAX_CREDIT_HOURS}
        step={1}
        aria-label="Credit Hours"
        className="relative flex h-5 w-48 touch-none items-center"
      >
        <SliderPrimitive.Track className="relative h-2 w-full grow rounded-full border border-solid border-[#ADADAD] bg-white">
          <SliderPrimitive.Range className="absolute h-full rounded-full bg-du-skyblue" />
        </SliderPrimitive.Track>
        <SliderPrimitive.Thumb
          className={
            "box-border block h-6 w-6 rounded-full border-4 border-solid border-du-skyblue bg-white focus-visible:border-du-black focus-visible:outline-none"
          }
        />
        <SliderPrimitive.Thumb
          className={
            "box-border block h-6 w-6 rounded-full border-4 border-solid border-du-skyblue bg-white focus-visible:border-du-black focus-visible:outline-none"
          }
        />
      </SliderPrimitive.Root>
    </div>
  );
};

export default CreditHoursField;

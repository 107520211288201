import * as Dialog from "@radix-ui/react-dialog";
import ModalWrapper from "components/ModalWrapper";
import React, { useEffect, useState } from "react";

interface AddToClassErrorProps {
  content: React.ReactNode;
  open: boolean;
  action: (open: boolean) => void;
}

const AddToClassError: React.FC<AddToClassErrorProps> = ({
  content,
  open,
  action,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  return (
    <Dialog.Root
      open={isOpen}
      onOpenChange={(open) => {
        setIsOpen(open);
        action(open);
      }}
    >
      <ModalWrapper show={isOpen} className="h-[150px] max-h-[150px] w-[55vw]">
        <div className="mb-6 flex justify-end">
          <Dialog.Close className="border-none text-3xl" aria-label="Close">
            &times;
          </Dialog.Close>
        </div>
        {content}
      </ModalWrapper>
    </Dialog.Root>
  );
};

export default AddToClassError;

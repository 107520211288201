import clsx from "clsx";
import Autocomplete from "components/autocomplete/Autocomplete";
import CaretDown from "icons/CaretDown";
import React, { useEffect, useState } from "react";
import { angularize } from "react-in-angularjs";
import getDuService from "ServiceBroker";
import {
  CampusLocation2Value,
  CampusLocationResponse,
} from "types/classSearchService";
import { GenericItem } from "types/select";

export interface LocationValueCustom
  extends GenericItem,
    CampusLocation2Value {}

interface LocationFieldProps {
  campusCode?: string;
}

const LocationField: React.FC<LocationFieldProps> = ({ campusCode }) => {
  const [location, setLocation] = useState<LocationValueCustom>({
    $id: "",
    $name: "",
    Code: "",
    Description: "",
    Name: "",
    short_description: "",
  });
  const [locationError, setLocationError] = useState(false);
  const [locationValuesCustom, setLocationValuesCustom] = useState<
    LocationValueCustom[]
  >([]);

  // temp code while class seach is converted to react
  const handleFieldError = () => {
    const location = JSON.parse(
      document.getElementById("react-class-search-location")?.textContent ?? ""
    ) as LocationValueCustom;
    const subject = JSON.parse(
      document.getElementById("react-class-search-subject")?.textContent ?? ""
    ) as LocationValueCustom;
    setLocationError(location.$id === "" && subject.$id === "");
  };

  useEffect(() => {
    const $submitButton = document.getElementById("class-search-submit-button");
    if (!$submitButton) return;

    const setLocationEvent = (e: any) => {
      setLocation(e.detail);
    };
    const setLocationErrorEvent = (e: any) => {
      setLocationError(e.detail);
    };

    $submitButton.addEventListener("click", handleFieldError);
    window.addEventListener("setLocationEvent", setLocationEvent);
    window.addEventListener("setLocationErrorEvent", setLocationErrorEvent);
    return () => {
      $submitButton.removeEventListener("click", handleFieldError);
      window.removeEventListener("setLocationEvent", setLocationEvent);
      window.removeEventListener(
        "setLocationErrorEvent",
        setLocationErrorEvent
      );
    };
  }, []);

  useEffect(() => {
    if (location.$id !== "") {
      setLocationError(false);
      window.dispatchEvent(
        new CustomEvent("setSubjectErrorEvent", { detail: false })
      );
    }
  }, [location]);
  // end temp

  const getLocations = async () => {
    try {
      const locationData = (await getDuService(
        {
          service: "classSearchService",
          method: "getCampusLocationList",
        },
        campusCode
      )) as CampusLocationResponse;

      setLocationValuesCustom(
        locationData?.campusLocation2Values.map((l) => ({
          ...l,
          $id: l.Code,
          $name: l.Description,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!campusCode) return;
    getLocations();
  }, [campusCode]);

  return (
    <div>
      <label
        htmlFor="cs-location"
        className={clsx(
          "mb-2.5 inline-block text-[15px] font-bold",
          locationError ? "text-du-red" : "text-du-black"
        )}
      >
        Location
      </label>
      <Autocomplete
        className={clsx(
          "box-border h-[40px] w-full border-[1px] border-solid pl-2.5 text-[15px] font-medium",
          locationError ? "border-du-red" : "border-[#ADADAD]"
        )}
        currItem={location}
        items={locationValuesCustom}
        filterItems={(item, inputVal) => {
          if (item.$name.toLowerCase().includes(inputVal.toLowerCase())) {
            return true;
          }
          return false;
        }}
        updateItem={(item) => {
          setLocation(item);
        }}
        icon={
          <label
            htmlFor="cs-location"
            className={clsx(
              "flex h-[38px] w-[40px] cursor-pointer items-center justify-center border-none",
              locationError ? "bg-du-red" : "bg-du-skyblue"
            )}
          >
            <CaretDown className="h-4 w-4" fillColor="white" />
          </label>
        }
        listAll
        inputId="cs-location"
        resetOnClick
      />
      {locationError && (
        <div className="mt-2.5 text-xs text-du-red">
          Location or Subject is required
        </div>
      )}
      <span className="hidden" id="react-class-search-location">
        {JSON.stringify(location)}
      </span>
    </div>
  );
};

angularize(LocationField, "locationFieldReact", angular.module("duApp"), {
  campusCode: "<",
});

export default LocationField;

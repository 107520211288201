import clsx from "clsx";
import React, { useContext } from "react";

import { ClassSearchContext } from "../classSearchContext";
import { resetAllEventChannel } from "../events/resetAllEventMsg";

const ResetAllButton = () => {
  const {
    state: {
      profile: { discountinuedOrAlumni },
    },
    classSearchState: {
      classResponseStatus,
      setClassResponseUI,
      setClassResponseStatus,
    },
    dispatch,
  } = useContext(ClassSearchContext);

  return (
    <button
      id="cs-reset-all-button"
      className={clsx(
        "all-revert flex cursor-pointer items-end gap-2 border-none bg-white bg-none text-sm font-normal text-du-blue hover:underline",
        classResponseStatus === "loading" ? "!cursor-not-allowed" : ""
      )}
      onClick={() => {
        dispatch({
          type: "RESET_ALL",
        });
        resetAllEventChannel.emit("onResetAll");
        setClassResponseUI({
          "@odata.context": "",
          "@odata.count": 0,
          "@odata.nextLink": "",
          value: [],
        });
        setClassResponseStatus("pristine");
      }}
      disabled={discountinuedOrAlumni}
    >
      Reset All
    </button>
  );
};

export default ResetAllButton;

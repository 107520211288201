import "./financialAid.css";

import ChevronDown from "icons/ChevronDown";
import ChevronRight from "icons/ChevronRight";
import ChevronUp from "icons/ChevronUp";
import React, { useEffect, useState } from "react";
import { angularize } from "react-in-angularjs";
import getDuService from "ServiceBroker";
import { TodosResponse } from "types/TodosResponse";

interface financialAidTOdos {
  INDEX: number;
  ADMIN_FUNC: string;
  TO_DO_ITEM: string;
  DUE_DATE: string;
  DUE_DATE_FORMATTED: any;
  STATUS: string;
  YEAR: string; // Extracted from the DUE_DATE field
  CONTACT: string;
  DESCRIPTION: string;
}

const FinToDoDetail = () => {
  const [financialAidTodos, setFinancialAidTodos] = useState<
    financialAidTOdos[]
  >([]);
  const [isDueAsc, setDueAsc] = useState(false);

  const getTodosInfo = async (sortKey = "") => {
    try {
      const todoInfo = (await getDuService({
        service: "todoService",
        method: "getTodos",
      })) as TodosResponse;

      const itemList = todoInfo.UC_DU_TO_DO_RESP.UC_DU_TO_DO_ITEM || [];
      const todoList: financialAidTOdos[] = [];

      // Extract the year from DUE_DATE and filter only Financial Aid To-Dos with STATUS "Initiated"
      itemList.forEach((item, index) => {
        if (
          (item.ADMIN_FUNC === "Financial Aid" ||
            item.ADMIN_FUNC == "Financial Aid Term") &&
          item.STATUS === "Initiated"
        ) {
          const YEAR = item.DUE_DATE.split("-")[0]; // Extract the year from DUE_DATE

          todoList.push({
            INDEX: index,
            ADMIN_FUNC: item.ADMIN_FUNC,
            TO_DO_ITEM: item.TO_DO_ITEM,
            DUE_DATE: item.DUE_DATE,
            DUE_DATE_FORMATTED: getDateFormat(item.DUE_DATE),
            STATUS: item.STATUS,
            YEAR: YEAR,
            CONTACT: item.UC_DU_TO_DO_DOC.CONTACT,
            DESCRIPTION: item.UC_DU_TO_DO_DOC.DESCRIPTION,
          });
        }
      });

      // Sort by DUE_DATE descending (full date sorting)
      if (sortKey == "sortbydueasc") {
        const sortedTodos = todoList.sort(
          (a, b) =>
            new Date(a.DUE_DATE).getTime() - new Date(b.DUE_DATE).getTime()
        );
        setFinancialAidTodos(sortedTodos);
        sessionStorage.setItem("FinancialTodos", JSON.stringify(sortedTodos));
      } else if (sortKey == "sortbyduedsc") {
        const sortedTodos = todoList.sort(
          (a, b) =>
            new Date(b.DUE_DATE).getTime() - new Date(a.DUE_DATE).getTime()
        );
        setFinancialAidTodos(sortedTodos);
        sessionStorage.setItem("FinancialTodos", JSON.stringify(sortedTodos));
      }
    } catch (error) {
      console.error("Error fetching To-Dos:", error);
    }
  };

  useEffect(() => {
    getTodosInfo("sortbydueasc");
  }, []);

  const getDateFormat = (date: string) => {
    const nDate = new Date(date);
    const month = (nDate.getMonth() + 1).toString().padStart(2, "0");
    const day = nDate.getUTCDate().toString().padStart(2, "0");
    const year = (nDate.getFullYear() % 100).toString().padStart(2, "0");
    const formattedDate = `${month}/${day}/${year}`;
    return formattedDate;
  };

  const sortByDueDate = () => {
    if (isDueAsc) {
      setDueAsc(false);
    } else {
      setDueAsc(true);
    }
    console.log(financialAidTodos);
  };

  useEffect(() => {
    setFinancialAidTodos(financialAidTodos);
  }, [financialAidTodos]);

  // Group To-Dos by the extracted YEAR
  const groupedTodos = financialAidTodos.reduce((grouped, todo) => {
    if (!grouped[todo.YEAR]) {
      grouped[todo.YEAR] = [];
    }
    grouped[todo.YEAR].push(todo);
    return grouped;
  }, {} as { [key: string]: financialAidTOdos[] });

  const handleClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    todo: financialAidTOdos
  ) => {
    event.preventDefault();
    // setData("FinancialTodoInfo");
    sessionStorage.setItem("todoList", JSON.stringify(todo));
    window.location.href = "#/fintoDoInfo/index=0";
  };

  return (
    <section
      aria-labelledby="Scholarships"
      id=""
      className="border-box resources-cls -webkit-shadow-[5px 5px 5px 0px rgba(0,0,0,0.1)]
       moz-shadow-[5px_5px_5px_0px_rgba(255,255,0,1)]  
       relative mb-[1.25rem] mr-[0.05rem] bg-[#ffffff]"
      style={{
        boxShadow: "5px 5px 5px 0px rgba(0,0,0,0.1)",
        marginLeft: "1.65rem",
        padding: "1.25rem 1.25rem 0",
      }}
    >
      <a
        className="mb-3 block cursor-pointer border-none font-bold text-[#003e6a] before:mr-1 before:inline-block before:font-awesome before:text-sm before:font-light before:content-['\f053']"
        href="#/finances"
      >
        Back to Finances
      </a>
      <div className="flex-justify-between">
        <div className="text-left">
          <h3
            className="text-[1.5em]"
            style={{
              fontFamily: "'Roboto', Tahoma, sans-serif",
              fontWeight: "300",
              lineHeight: "1.4em",
              borderBottom: "0.5px solid #ccc",
              paddingBottom: "1rem",
            }}
          >
            <img
              src="https://portalstg.umgc.edu/images/du-todo-icon.svg"
              alt="icon"
              style={{
                width: "25px",
                height: "25px",
                display: "inline-flex",
              }}
            />{" "}
            Financial Aid To Dos ({financialAidTodos.length})
          </h3>
        </div>
      </div>

      <div
        className="flex-justify-between"
        style={{
          paddingBottom: "1rem",
          borderBottom: "0.5px solid #ccc",
        }}
      >
        <div>
          <section className="du-fintodo-content-section">
            {financialAidTodos && financialAidTodos.length > 0 && (
              <table
                id="du-todo-table"
                className="du-table du-table-left-align"
              >
                <thead>
                  <tr className="fin-todo-th">
                    <th className="du-no-bottom-padding du-no-top-padding">
                      <span className="fin-todo-all-title">Name</span>
                    </th>
                    <th className="du-no-bottom-padding du-no-top-padding">
                      <span className="fin-todo-all-title">
                        Due Date{" "}
                        {isDueAsc && (
                          <ChevronDown
                            className="w-3"
                            onClick={sortByDueDate}
                          />
                        )}
                        {!isDueAsc && (
                          <ChevronUp className="w-2" onClick={sortByDueDate} />
                        )}
                      </span>
                    </th>
                    <th
                      className="du-no-bottom-padding desktop-view du-no-top-padding align-items-center flex"
                      style={{
                        flexWrap: "wrap",
                        alignItems: "center",
                      }}
                    >
                      <span className="fin-todo-all-title group relative after:mt-2 after:font-awesome after:text-sm after:font-light after:content-['\f05a']">
                        Status
                        <span className="absolute  -left-[100px] top-[34px] m-4 mx-auto w-[350px] rounded-md bg-du-light-gray p-2 text-[0.8rem] text-sm opacity-0 transition-opacity group-hover:opacity-100">
                          Initiated status indicates To Dos have been entered
                          for your account and activation is pending. Active
                          status indicates To Dos are currently live for your
                          account and need to be resolved.
                        </span>
                      </span>{" "}
                      {/* <ChevronDown className="mb-3 mr-3 h-7 w-6" /> */}
                    </th>
                    <th className="du-no-bottom-padding desktop-view du-no-top-padding">
                      <span className="fin-todo-all-title">
                        Department
                        {/* <ChevronDown className="w-6" /> */}
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(groupedTodos)
                    .sort((a: string, b: string) => {
                      return isDueAsc
                        ? Number(a) - Number(b)
                        : Number(b) - Number(a);
                    })
                    .map((year) => (
                      <div key={year} className="contents">
                        {groupedTodos[year].map((todo, index) => (
                          <tr className="fin-todo-row" key={index}>
                            <td>
                              <a
                                href="#"
                                title={todo.TO_DO_ITEM}
                                onClick={(event) => handleClick(event, todo)}
                              >
                                {todo.TO_DO_ITEM}
                              </a>
                            </td>
                            <td>
                              <span>{todo.DUE_DATE_FORMATTED}</span>
                            </td>
                            <td className="desktop-view">
                              <span>{todo.STATUS}</span>
                            </td>
                            <td className="desktop-view">
                              <span>{todo.ADMIN_FUNC}</span>
                            </td>
                          </tr>
                        ))}
                      </div>
                    ))}
                </tbody>
              </table>
            )}
          </section>
        </div>
      </div>
    </section>
  );
};

angularize(FinToDoDetail, "finToDoDetailReact", angular.module("duApp"), {});
export default FinToDoDetail;

import clsx from "clsx";
import Header from "components/Header";
import React, { Fragment, useEffect, useState } from "react";
import { useWindowSize } from "usehooks-ts";

import AvailableForRegistrationField from "./fields/AvailableForRegistrationField";
import CreditHoursField from "./fields/CreditHoursField";
import DaysOfWeekField from "./fields/DaysOfWeekField";
import SearchCatalogField from "./fields/SearchCatalogField";
import Personalize from "./Personalize";

const LeftNavbar = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);
  const [checkMobileView, setCheckMobileView] = useState(false);
  const [sideBarHeight, setSideBarHeight] = useState(0);
  const { width } = useWindowSize();

  useEffect(() => {
    if (width < 600) {
      setIsMobileView(true);
    } else {
      setIsMobileView(false);
    }

    // Collapse the sidebar if the width is less than 600px
    if (width !== 0 && width < 600 && !checkMobileView) {
      setIsCollapsed(true);
      setCheckMobileView(true);
    }
  }, [width]);

  useEffect(() => {
    setTimeout(() => {
      const sideBarHeight =
        document.getElementById("left-gutter")?.clientHeight;
      if (sideBarHeight) {
        setSideBarHeight(sideBarHeight);
      }
    }, 1000);
  }, []);

  return (
    <Fragment>
      <div
        style={sideBarHeight > 0 ? { minHeight: sideBarHeight } : {}}
        className={clsx(
          "z-10 bg-du-light-gray py-2",
          isCollapsed
            ? "w-[40px] min-w-[40px] px-2"
            : "w-[230px] min-w-[230px] px-4",
          !isCollapsed && isMobileView && "absolute h-[100%] shadow-lg"
        )}
      >
        <div
          className={clsx(
            "mb-3 flex border-b border-du-dark-gray",
            isCollapsed ? "justify-center px-0 py-3" : "justify-between p-3"
          )}
        >
          <div className="h-[32px]">
            <Header className={clsx("!text-2xl", isCollapsed && "hidden")}>
              Filter By
            </Header>
          </div>
          <div className="relative flex items-center justify-center">
            <button
              id="cs-show-hide-left-nav"
              onClick={() => {
                setIsCollapsed((prev) => !prev);
              }}
              className="text-lg"
              aria-label={isCollapsed ? "Open Filter by" : "Close Filter by"}
              aria-expanded={!isCollapsed}
              aria-controls="filter-content"
            >
              <div
                className={clsx(
                  "absolute top-0 flex h-8 w-8 items-center justify-center rounded-full bg-white",
                  isCollapsed ? "left-[17px]" : "left-[98px]"
                )}
              >
                <img
                  className={clsx(
                    "h-5 w-5 object-contain",
                    !isCollapsed && "hidden"
                  )}
                  src="/umgc-apps/common/images/icon-hamburguer-right-arrow.png"
                  alt=""
                />
                <img
                  className={clsx(
                    "h-5 w-5 object-contain",
                    isCollapsed && "hidden"
                  )}
                  src="/umgc-apps/common/images/icon-hamburguer-left-arrow.png"
                  alt=""
                />
              </div>
            </button>
          </div>
        </div>
        <div
          id="filter-content"
          className={clsx("space-y-7", isCollapsed && "hidden")}
        >
          <Personalize />
          <AvailableForRegistrationField />
          <SearchCatalogField />
          <DaysOfWeekField />
          <CreditHoursField />
        </div>
      </div>
      <div
        className={clsx(
          "h-full w-[43.5px]",
          !isCollapsed && isMobileView ? "block" : "hidden"
        )}
      />
    </Fragment>
  );
};

export default LeftNavbar;

import { Semester, SemesterUI, StarDateUI } from "./StartDateField";

export const getSemesterUIList = (semesters: Semester[]): SemesterUI[] => {
  const semesterUIList: SemesterUI[] = [];
  const semestersUnique = new Set<string>();

  for (let i = 0; i < semesters.length; i++) {
    const semester = semesters[i];
    semestersUnique.add(`${semester.semesterCode}-${semester.semesterName}`);
  }

  const semestersUniqueArr = Array.from(semestersUnique);
  for (let i = 0; i < semestersUniqueArr.length; i++) {
    const semester = semestersUniqueArr[i];
    const [code, name] = semester.split("-");
    const startDates = semesters
      .filter((semester) => semester.semesterCode === code)
      .map((semester) => ({
        startDate: semester.sessionStartDate,
        endDate: semester.sessionEndDate,
        semesterStartDate: semester.semesterStartDate,
        semesterEndDate: semester.semesterEndDate,
        isSelected: false,
      }))
      .sort(
        (a, b) =>
          new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
      );

    let startDatesUnique = startDates.reduce(
      (acc, curr) => ({ ...acc, [curr.startDate]: curr }),
      {}
    );
    startDatesUnique = Object.values(startDatesUnique);

    semesterUIList.push({
      semesterCode: code,
      semesterName: name,
      startDates: startDatesUnique as StarDateUI[],
      isSelected: i === 0,
      fromDate: startDates[0].startDate,
      toDate: startDates[startDates.length - 1].endDate,
      semesterStartDate: startDates[0].semesterStartDate,
      semesterEndDate: startDates[0].semesterEndDate,
    });
  }

  return semesterUIList;
};

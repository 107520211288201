import React from "react";

interface CheckCircleProps extends React.SVGProps<SVGSVGElement> {
  fillColor?: string;
}

const CheckCircle: React.FC<CheckCircleProps> = ({ fillColor, ...props }) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="-10 0 524 512"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M256 8c-136.967 0 -248 111.033 -248 248s111.033 248 248 248s248 -111.033 248 -248s-111.033 -248 -248 -248zM256 472c-118.664 0 -216 -96.0547 -216 -216c0 -118.663 96.0547 -216 216 -216c118.664 0 216 96.0547 216 216c0 118.663 -96.0547 216 -216 216z
        M397.63 197.039l-180.479 179.032c-4.70508 4.66699 -12.3037 4.63672 -16.9707 -0.0683594l-85.8779 -86.5723c-4.66699 -4.70508 -4.63672 -12.3027 0.0683594 -16.9697l8.51953 -8.45117c4.70508 -4.66699 12.3037 -4.63672 16.9707 0.0683594l68.9756 69.5332
        l163.441 -162.13c4.70508 -4.66699 12.3027 -4.6377 16.9697 0.0673828l8.45117 8.52051c4.66797 4.70508 4.63672 12.3027 -0.0683594 16.9697z"
        fill={fillColor ? fillColor : "#ffffff"}
      />
    </svg>
  );
};

export default CheckCircle;

import HighlightedOption from "components/HighlightedOption";
import CircleInfo from "icons/CircleInfo";
import { resetAllEventChannel } from "pages/newClassSearch/events/resetAllEventMsg";
import React, { useContext, useEffect, useState } from "react";
import ReactSelect from "react-select";

import { ClassSearchContext } from "../../classSearchContext";
import { EField, fetchValues, IAND, IField, IOR } from "../../classSearchQuery";
import { ClassSearchResponse } from "../../types";

interface LocationItem {
  locationCode: string;
  locationName: string;
}

const LocationField = () => {
  const [locations, setLocations] = useState<LocationItem[]>([]);
  const [selectCode, setSelectCode] = useState("");
  const [isLocationDisabled, setIsLocationDisabled] = useState(false);

  const {
    state: {
      fields: {
        campusCode,
        academicCareerCode,
        sessionStartDateList,
        classFormatCodeList,
      },
    },
    dispatch,
  } = useContext(ClassSearchContext);

  const fetchLocations = async () => {
    try {
      const conditionList: (IAND | IOR | IField)[] = [
        {
          campusCode: {
            equals: campusCode,
          },
        },
        {
          academicCareerCode: {
            equals: academicCareerCode,
          },
        },
        {
          locationCode: {
            notEquals: "WEB",
          },
        },
      ];

      if (sessionStartDateList.length > 0) {
        conditionList.push({
          OR: sessionStartDateList.map((startDate) => ({
            sessionStartDate: {
              equals: startDate,
            },
          })),
        });
      }

      const classSearchPagination = (await fetchValues({
        filter: {
          AND: conditionList,
        },
        select: [EField.locationCode, EField.locationName],
        orderby: {
          locationName: "asc",
        },
      })) as ClassSearchResponse;

      setLocations(
        classSearchPagination.value.map((item) => ({
          locationCode: item.locationCode,
          locationName: item.locationName,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (campusCode !== "" && academicCareerCode !== "") {
      fetchLocations();
    }
  }, [campusCode, academicCareerCode, sessionStartDateList]);

  useEffect(() => {
    const unsubscribeOnResetAll = resetAllEventChannel.on("onResetAll", () => {
      setSelectCode(String(new Date().getTime()));
    });

    return () => {
      unsubscribeOnResetAll();
    };
  }, []);

  useEffect(() => {
    const onlineCode = classFormatCodeList.find((code) => code === "WT");
    if (onlineCode && classFormatCodeList.length === 1) {
      setSelectCode(String(new Date().getTime()));
      dispatch({
        type: "UPDATE_LOCATION_CODE_LIST",
        payload: {
          locationCodeList: [],
        },
      });
      setIsLocationDisabled(true);
    } else {
      setIsLocationDisabled(false);
    }
  }, [classFormatCodeList]);

  return (
    <div className="inline-block min-h-[90px]  rounded-md bg-du-light-gray px-4 py-2">
      <div className="mb-2 flex justify-between">
        <p className="flex items-center gap-1 text-sm font-bold">
          Location
          {isLocationDisabled && (
            <div className="group">
              <CircleInfo className="h-4 w-4" />
              <div className="invisible absolute mt-[10px] w-[200px] space-y-2 rounded bg-du-black px-2 py-2 text-sm shadow-lg group-hover:visible group-hover:z-50">
                <p className="text-sm text-white">
                  Location field is unable to be used until a class format other
                  than online is selected.
                </p>
              </div>
            </div>
          )}
        </p>
        <button
          id="cs-reset-location"
          className="cursor-pointer text-sm font-semibold text-[#24559A]"
          onClick={() => {
            setSelectCode(String(new Date().getTime()));
            dispatch({
              type: "UPDATE_LOCATION_CODE_LIST",
              payload: {
                locationCodeList: [],
              },
            });
          }}
        >
          Reset
        </button>
      </div>
      <div>
        <ReactSelect
          id="cs-location-multi-select-field"
          isDisabled={isLocationDisabled}
          key={`location-multi-select-field-${selectCode}`}
          name="location-multi-select-field"
          isMulti
          options={locations}
          onChange={(values) => {
            const newLocationCodeList = values.map(
              (item) => (item as LocationItem).locationCode
            );
            dispatch({
              type: "UPDATE_LOCATION_CODE_LIST",
              payload: {
                locationCodeList: newLocationCodeList,
              },
            });
          }}
          getOptionLabel={(option) => (option as LocationItem).locationName}
          getOptionValue={(option) => (option as LocationItem).locationCode}
          components={{ DropdownIndicator: null, Option: HighlightedOption }}
          placeholder="Select one or more locations"
        />
      </div>
    </div>
  );
};

export default LocationField;

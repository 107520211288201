import "./style.css";

import clsx from "clsx";
import OutLink from "components/OutLink";
import CaretDown from "icons/CaretDown";
import CaretRight from "icons/CaretRight";
import ExternalLink from "icons/ExternalLink";
import React, { Fragment, useState } from "react";
import { angularize } from "react-in-angularjs";

interface FooterTopHalfProps {
  showingProfileValidation?: boolean;
  shouldNotDisplaySidebar?: boolean;
  impersonate?: string;
  adelphiStudent?: string;
  asiaStudent?: string;
  europeStudent?: string;
  dutyStationAdvisorEmail?: string;
}

const FooterTopHalf: React.FC<FooterTopHalfProps> = ({
  showingProfileValidation,
  shouldNotDisplaySidebar,
  impersonate,
  adelphiStudent,
  asiaStudent,
  europeStudent,
  dutyStationAdvisorEmail,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [isCollapsedGroup, setIsCollapsedGroup] = useState({
    academicAndResources: true,
    academicAdvising: true,
    contactUs: true,
  });

  if (
    (showingProfileValidation == false && shouldNotDisplaySidebar == false) ||
    impersonate == "true"
  ) {
    return (
      <Fragment>
        <div className="hide-mobile-view">
          <div className="bg-du-gray px-[20px]">
            <ul>
              <li className="border-0 border-b border-solid border-du-dark-gray py-[15px]">
                <div
                  className={clsx(
                    "flex",
                    !isCollapsedGroup.academicAndResources && "mb-[15px]"
                  )}
                >
                  <button
                    tabIndex={0}
                    className="all-revert mr-[10px] flex items-center border-0 bg-du-gray"
                    onClick={() => {
                      setIsCollapsedGroup((prev) => ({
                        ...prev,
                        academicAndResources: !prev.academicAndResources,
                      }));
                    }}
                  >
                    {isCollapsedGroup.academicAndResources ? (
                      <CaretRight className="h-4" fillColor="#3c3c43" />
                    ) : (
                      <CaretDown className="h-4" fillColor="#3c3c43" />
                    )}
                  </button>
                  <p className="text-[14px] font-bold">
                    Academic & Learning Resources
                  </p>
                </div>
                <ul
                  className={clsx(
                    "ml-[18px] space-y-[8px]",
                    isCollapsedGroup.academicAndResources && "hidden"
                  )}
                >
                  <li>
                    {adelphiStudent && (
                      <OutLink
                        className="!text-[14px]"
                        href="https://www.umgc.edu/current-students/course-registration/academic-calendar"
                      >
                        Academic Calendar{" "}
                      </OutLink>
                    )}
                    {asiaStudent && (
                      <OutLink
                        className="!text-[14px]"
                        href="https://asia.umgc.edu/students/calendar-and-classes/academic-calendar"
                      >
                        Academic Calendar{" "}
                      </OutLink>
                    )}
                    {europeStudent && (
                      <OutLink
                        className="!text-[14px]"
                        href="https://europe.umgc.edu/students/calendars-and-classes/academic-calendar"
                      >
                        Academic Calendar{" "}
                      </OutLink>
                    )}
                  </li>
                  <li>
                    <OutLink
                      className="!text-[14px]"
                      href="http://www.umgc.edu/current-students/student-life-and-support/accessibility-accommodations/index.cfm"
                    >
                      Accessibility Accommodations{" "}
                    </OutLink>
                  </li>
                  <li>
                    <OutLink
                      className="!text-[14px]"
                      href="http://www.umgc.edu/about/accreditation.cfm"
                    >
                      Accreditation & State Licensure{" "}
                    </OutLink>
                  </li>
                  <li>
                    <OutLink
                      className="!text-[14px]"
                      href="https://bncvirtual.com/umgc.htm"
                    >
                      Book Store{" "}
                    </OutLink>
                  </li>
                  <li>
                    <OutLink
                      className="!text-[14px]"
                      href="http://www.umgc.edu/current-students/career-services/index.cfm"
                    >
                      Career Services{" "}
                    </OutLink>
                  </li>
                  <li>
                    <OutLink
                      className="!text-[14px]"
                      href="http://www.umgc.edu/terms-and-conditions/disclosures/index.cfm"
                    >
                      Consumer Disclosures & Policies{" "}
                    </OutLink>
                  </li>
                  <li>
                    <OutLink
                      className="!text-[14px]"
                      href="https://umgc.onthehub.com/"
                    >
                      Educational Software{" "}
                    </OutLink>
                  </li>
                  <li>
                    <OutLink
                      className="!text-[14px]"
                      href="https://www.umgc.edu/current-students/learning-resources/writing-center/online-guide-to-writing"
                    >
                      Online Guide to Writing{" "}
                    </OutLink>
                  </li>
                  <li>
                    {adelphiStudent && (
                      <OutLink
                        className="!text-[14px]"
                        href="https://www.umgc.edu/current-students/student-life-and-support"
                      >
                        Student Life and Support{" "}
                      </OutLink>
                    )}
                    {asiaStudent && (
                      <OutLink
                        className="!text-[14px]"
                        href="https://asia.umgc.edu/students/student-life-and-support"
                      >
                        Student Life and Support{" "}
                      </OutLink>
                    )}
                    {europeStudent && (
                      <OutLink
                        className="!text-[14px]"
                        href="https://europe.umgc.edu/students/student-life-and-support"
                      >
                        Student Life and Support{" "}
                      </OutLink>
                    )}
                  </li>
                  <li>
                    <OutLink
                      className="!text-[14px]"
                      href="https://www.umgc.edu/current-students/learning-resources/writing-center/submit-your-paper.html"
                    >
                      Submit to Writing Center{" "}
                    </OutLink>
                  </li>
                  <li>
                    <OutLink
                      className="!text-[14px]"
                      href="http://www.umgc.edu/administration/policies-and-reporting/sexual-misconduct-title-ix/index.cfm"
                    >
                      Title IX/Sexual Misconduct{" "}
                    </OutLink>
                  </li>
                  <li>
                    <OutLink
                      className="!text-[14px]"
                      href="http://gear.umgc.edu"
                    >
                      UMGC Gear{" "}
                    </OutLink>
                  </li>
                  <li>
                    <OutLink
                      className="!text-[14px]"
                      href="http://sites.umgc.edu/library/index.cfm"
                    >
                      UMGC Library{" "}
                    </OutLink>
                  </li>
                </ul>
              </li>
              <li className="border-0 border-b border-solid border-du-dark-gray py-[15px]">
                <div
                  className={clsx(
                    "flex",
                    !isCollapsedGroup.academicAdvising && "mb-[15px]"
                  )}
                >
                  <button
                    className="all-revert mr-[10px] flex items-center border-0 bg-du-gray"
                    onClick={() => {
                      setIsCollapsedGroup((prev) => ({
                        ...prev,
                        academicAdvising: !prev.academicAdvising,
                      }));
                    }}
                  >
                    {isCollapsedGroup.academicAdvising ? (
                      <CaretRight className="h-4" fillColor="#3c3c43" />
                    ) : (
                      <CaretDown className="h-4" fillColor="#3c3c43" />
                    )}
                  </button>
                  <p className="text-[14px] font-bold">Academic Advising</p>
                </div>
                <ul
                  className={clsx(
                    "ml-[18px] space-y-[8px]",
                    isCollapsedGroup.academicAdvising && "hidden"
                  )}
                >
                  {adelphiStudent && (
                    <li className="h-[18px] leading-[1px]">
                      <OutLink className="!text-[14px]" href="tel:8556558682">
                        855-655-8682{" "}
                      </OutLink>
                    </li>
                  )}
                  {asiaStudent && (
                    <li className="h-[18px] leading-[1px]">
                      <OutLink
                        className="!text-[14px]"
                        href="https://asia.umgc.edu/academic-advising"
                      >
                        Contact Asia Advising{" "}
                      </OutLink>
                    </li>
                  )}
                  {europeStudent && (
                    <li className="h-[18px] leading-[1px]">
                      <OutLink
                        className="!text-[14px]"
                        href="https://europe.umgc.edu/academic-advising"
                      >
                        Contact Europe Advising{" "}
                      </OutLink>
                    </li>
                  )}
                  <li className="h-[18px] leading-[1px]">
                    <OutLink
                      className="!text-[14px]"
                      href={`mailto:${dutyStationAdvisorEmail}`}
                    >
                      {dutyStationAdvisorEmail}{" "}
                    </OutLink>
                  </li>
                </ul>
              </li>
              <li className="border-0 py-[15px]">
                <div
                  className={clsx(
                    "flex",
                    !isCollapsedGroup.contactUs && "mb-[15px]"
                  )}
                >
                  <button
                    className="all-revert mr-[10px] flex items-center border-0 bg-du-gray"
                    onClick={() => {
                      setIsCollapsedGroup((prev) => ({
                        ...prev,
                        contactUs: !prev.contactUs,
                      }));
                    }}
                  >
                    {isCollapsedGroup.contactUs ? (
                      <CaretRight className="h-4" fillColor="#3c3c43" />
                    ) : (
                      <CaretDown className="h-4" fillColor="#3c3c43" />
                    )}
                  </button>
                  <p className="text-[14px] font-bold">Contact Us</p>
                </div>
                <ul
                  className={clsx(
                    "ml-[18px] space-y-[8px]",
                    isCollapsedGroup.contactUs && "hidden"
                  )}
                >
                  <li className="h-[18px] leading-[1px]">
                    <OutLink
                      className="!text-[14px]"
                      href="https://www.umgc.edu/help/index.cfm"
                    >
                      Get Help{" "}
                    </OutLink>
                  </li>
                  <li className="h-[18px] leading-[1px]">
                    <OutLink
                      className="!text-[14px]"
                      href="http://www.umgc.edu/contact/a-to-z-directory.cfm"
                    >
                      A to Z Directory{" "}
                    </OutLink>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <div className="hide-desktop-view">
          <div className="remove-border-l mx-auto box-border max-w-[90rem] border-0 border-l-[202px] border-solid border-du-red bg-du-gray px-5 pt-3">
            <div className="flex justify-end">
              <div>
                <button
                  className="mr-[10px] border-0"
                  aria-label={
                    isCollapsed == true ? "Expand All" : "Collapse All"
                  }
                  onClick={() => {
                    setIsCollapsed((prev) => !prev);
                  }}
                >
                  {isCollapsed ? (
                    <CaretRight
                      className="h-[18px] w-[18px]"
                      fillColor="#3c3c43"
                    />
                  ) : (
                    <CaretDown
                      className="h-[18px] w-[18px]"
                      fillColor="#3c3c43"
                    />
                  )}
                </button>
              </div>
              <div>
                <p className="mb-[12px] mr-[15px] box-content h-[19px] w-[224px] border-0 border-r border-solid border-du-dark-gray pr-[15px] text-[12px] font-black uppercase">
                  academic & learning resources
                </p>
                <ul className={clsx("mb-3 w-[224px]", isCollapsed && "hidden")}>
                  <li className="h-[18px] leading-[1px]">
                    {adelphiStudent && (
                      <OutLink
                        className="w !text-[11px] after:!text-[11px]"
                        iconClassName="!w-3.5 !h-3.5"
                        href="https://www.umgc.edu/current-students/course-registration/academic-calendar"
                      >
                        Academic Calendar{" "}
                      </OutLink>
                    )}
                    {asiaStudent && (
                      <OutLink
                        className="!text-[11px] after:!text-[11px]"
                        iconClassName="!w-3.5 !h-3.5"
                        href="https://asia.umgc.edu/students/calendar-and-classes/academic-calendar"
                      >
                        Academic Calendar{" "}
                      </OutLink>
                    )}
                    {europeStudent && (
                      <OutLink
                        className="!text-[11px] after:!text-[11px]"
                        iconClassName="!w-3.5 !h-3.5"
                        href="https://europe.umgc.edu/students/calendars-and-classes/academic-calendar"
                      >
                        Academic Calendar{" "}
                      </OutLink>
                    )}
                  </li>
                  <li className="h-[18px] leading-[1px]">
                    <OutLink
                      className="!text-[11px] after:!text-[11px]"
                      iconClassName="!w-3.5 !h-3.5"
                      href="http://www.umgc.edu/current-students/student-life-and-support/accessibility-accommodations/index.cfm"
                    >
                      Accessibility Accommodations{" "}
                    </OutLink>
                  </li>
                  <li className="h-[18px] leading-[1px]">
                    <OutLink
                      className="!text-[11px] after:!text-[11px]"
                      iconClassName="!w-3.5 !h-3.5"
                      href="http://www.umgc.edu/about/accreditation.cfm"
                    >
                      Accreditation & State Licensure{" "}
                    </OutLink>
                  </li>
                  <li className="h-[18px] leading-[1px]">
                    <OutLink
                      className="!text-[11px] after:!text-[11px]"
                      iconClassName="!w-3.5 !h-3.5"
                      href="https://bncvirtual.com/umgc.htm"
                    >
                      Book Store{" "}
                    </OutLink>
                  </li>
                  <li className="h-[18px] leading-[1px]">
                    <OutLink
                      className="!text-[11px] after:!text-[11px]"
                      iconClassName="!w-3.5 !h-3.5"
                      href="http://www.umgc.edu/current-students/career-services/index.cfm"
                    >
                      Career Services{" "}
                    </OutLink>
                  </li>
                  <li className="h-[18px] leading-[1px]">
                    <OutLink
                      className="!text-[11px] after:!text-[11px]"
                      iconClassName="!w-3.5 !h-3.5"
                      href="http://www.umgc.edu/terms-and-conditions/disclosures/index.cfm"
                    >
                      Consumer Disclosures & Policies{" "}
                    </OutLink>
                  </li>
                  <li className="h-[18px] leading-[1px]">
                    <OutLink
                      className="!text-[11px] after:!text-[11px]"
                      iconClassName="!w-3.5 !h-3.5"
                      href="https://umgc.onthehub.com/"
                    >
                      Educational Software{" "}
                    </OutLink>
                  </li>
                  <li className="h-[18px] leading-[1px]">
                    <OutLink
                      className="!text-[11px] after:!text-[11px]"
                      iconClassName="!w-3.5 !h-3.5"
                      href="https://www.umgc.edu/current-students/learning-resources/writing-center/online-guide-to-writing"
                    >
                      Online Guide to Writing{" "}
                    </OutLink>
                  </li>
                  <li className="h-[18px] leading-[1px]">
                    {adelphiStudent && (
                      <OutLink
                        className="!text-[11px] after:!text-[11px]"
                        iconClassName="!w-3.5 !h-3.5"
                        href="https://www.umgc.edu/current-students/student-life-and-support"
                      >
                        Student Life and Support{" "}
                      </OutLink>
                    )}
                    {asiaStudent && (
                      <OutLink
                        className="!text-[11px] after:!text-[11px]"
                        iconClassName="!w-3.5 !h-3.5"
                        href="https://asia.umgc.edu/students/student-life-and-support"
                      >
                        Student Life and Support{" "}
                      </OutLink>
                    )}
                    {europeStudent && (
                      <OutLink
                        className="!text-[11px] after:!text-[11px]"
                        iconClassName="!w-3.5 !h-3.5"
                        href="https://europe.umgc.edu/students/student-life-and-support"
                      >
                        Student Life and Support{" "}
                      </OutLink>
                    )}
                  </li>
                  <li className="h-[18px] leading-[1px]">
                    <OutLink
                      className="!text-[11px] after:!text-[11px]"
                      iconClassName="!w-3.5 !h-3.5"
                      href="https://www.umgc.edu/current-students/learning-resources/writing-center/submit-your-paper.html"
                    >
                      Submit to Writing Center{" "}
                    </OutLink>
                  </li>
                  <li className="h-[18px] leading-[1px]">
                    <OutLink
                      className="!text-[11px] after:!text-[11px]"
                      iconClassName="!w-3.5 !h-3.5"
                      href="http://www.umgc.edu/administration/policies-and-reporting/sexual-misconduct-title-ix/index.cfm"
                    >
                      Title IX/Sexual Misconduct{" "}
                    </OutLink>
                  </li>
                  <li className="h-[18px] leading-[1px]">
                    <OutLink
                      className="!text-[11px] after:!text-[11px]"
                      iconClassName="!w-3.5 !h-3.5"
                      href="http://gear.umgc.edu"
                    >
                      UMGC Gear{" "}
                    </OutLink>
                  </li>
                  <li className="h-[18px] leading-[1px]">
                    <OutLink
                      className="!text-[11px] after:!text-[11px]"
                      iconClassName="!w-3.5 !h-3.5"
                      href="http://sites.umgc.edu/library/index.cfm"
                    >
                      UMGC Library{" "}
                    </OutLink>
                  </li>
                </ul>
              </div>
              <div>
                <p className="mb-[12px] mr-[15px] h-[19px] w-[165px] border-0 border-r border-solid border-du-dark-gray pr-[15px] text-[12px] font-black uppercase">
                  academic advising
                </p>
                <ul className={clsx("w-[165px]", isCollapsed && "hidden")}>
                  {adelphiStudent && (
                    <li className="h-[18px] leading-[1px]">
                      <OutLink
                        className="!text-[11px] after:!text-[11px]"
                        iconClassName="!w-3.5 !h-3.5"
                        href="tel:8556558682"
                      >
                        855-655-8682{" "}
                      </OutLink>
                    </li>
                  )}
                  {asiaStudent && (
                    <li className="h-[18px] leading-[1px]">
                      <OutLink
                        className="!text-[11px] after:!text-[11px]"
                        iconClassName="!w-3.5 !h-3.5"
                        href="https://asia.umgc.edu/academic-advising"
                      >
                        Contact Asia Advising{" "}
                      </OutLink>
                    </li>
                  )}
                  {europeStudent && (
                    <li className="h-[18px] leading-[1px]">
                      <OutLink
                        className="!text-[11px] after:!text-[11px]"
                        iconClassName="!w-3.5 !h-3.5"
                        href="https://europe.umgc.edu/academic-advising"
                      >
                        Contact Europe Advising{" "}
                      </OutLink>
                    </li>
                  )}
                  <li className="leading-[12px]">
                    <a
                      className="text-[11px] font-bold text-du-blue hover:cursor-pointer hover:underline"
                      href={`mailto:${dutyStationAdvisorEmail}`}
                    >
                      {dutyStationAdvisorEmail}
                      <ExternalLink
                        fillColor="#003e6a"
                        className="ml-1 inline-block h-3.5 w-3.5"
                      />
                    </a>
                  </li>
                </ul>
              </div>
              <div>
                <p className="mb-[12px] h-[19px] w-[104px] text-[12px] font-black uppercase">
                  contact us
                </p>
                <ul className={clsx("w-[104px]", isCollapsed && "hidden")}>
                  <li className="h-[18px] leading-[1px]">
                    <OutLink
                      className="!text-[11px] after:!text-[11px]"
                      iconClassName="!w-3.5 !h-3.5"
                      href="https://www.umgc.edu/help/index.cfm"
                    >
                      Get Help{" "}
                    </OutLink>
                  </li>
                  <li className="h-[18px] leading-[1px]">
                    <OutLink
                      className="!text-[11px] after:!text-[11px]"
                      iconClassName="!w-3.5 !h-3.5"
                      href="http://www.umgc.edu/contact/a-to-z-directory.cfm"
                    >
                      A to Z Directory{" "}
                    </OutLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }

  return <div />;
};

angularize(FooterTopHalf, "footerTopHalfReact", angular.module("duApp"), {
  showingProfileValidation: "<",
  shouldNotDisplaySidebar: "<",
  impersonate: "<",
  adelphiStudent: "<",
  asiaStudent: "<",
  europeStudent: "<",
  dutyStationAdvisorEmail: "<",
});
export default FooterTopHalf;

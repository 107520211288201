import getDuService from "ServiceBroker";
import {
  ClassSectionResponse,
  CourseSection,
  ShoppingCartResponse,
  SubjectValueResponse,
} from "types/classSearchService";
import {
  PlannerCoursesResponse,
  ProgramDetailsResponse,
  UcMyplanCRSPlnr,
} from "types/degreePlanningDashboardService";

export const getClassSectionResponse = async (finalUrl: string) => {
  let classSectionResponse: ClassSectionResponse | undefined = undefined;
  if (finalUrl.charAt(0) === "/") {
    classSectionResponse = await (getDuService(
      {
        service: "classSearchService",
        method: "getSearchData",
      },
      finalUrl
    ) as Promise<ClassSectionResponse>);
    // check if it returns an error
  } else if (finalUrl.charAt(0) === "&") {
    classSectionResponse = await (getDuService(
      {
        service: "classSearchService",
        method: "getSearchLocData",
      },
      finalUrl
    ) as Promise<ClassSectionResponse>);
  }
  return classSectionResponse;
};

export const getSubjectListResponse = async () => {
  const subjectListResponse = (await getDuService({
    service: "classSearchService",
    method: "getSubjectList",
  })) as SubjectValueResponse;
  if (subjectListResponse.subjectValues)
    return subjectListResponse.subjectValues;
  return [];
};

export const getPlannerCoursesResponse = async (career: string) => {
  const programDetailsResponse = (await getDuService(
    {
      service: "dashBoardService",
      method: "getProgramDetails",
    },
    career
  )) as ProgramDetailsResponse;

  const degreeList =
    programDetailsResponse?.UC_MYPLAN_PROG_RESP?.UC_MYPLAN_PROG;
  if (!degreeList) return;

  let program = "";
  for (let i = 0; i < degreeList.length; i++) {
    const degreeItem = degreeList[i];
    program = degreeItem.ACAD_PROG;
  }

  const careers = ["UGRD", "GRAD", "GR4T"];
  const programUrls = [];
  for (let i = 0; i < careers.length; i++) {
    const career = careers[i];
    programUrls.push(`${career}/${program}`);
  }
  if (!careers.find((c) => c === career)) {
    programUrls.push(`${career}/${program}`);
  }

  const promises = programUrls.map((pu) =>
    getDuService(
      {
        service: "dashBoardService",
        method: "getCoursesList",
      },
      pu
    )
  ) as Promise<PlannerCoursesResponse>[];

  const values = await Promise.all(promises.map((p) => p.catch((e) => e)));
  const validValues = values.filter((result) => !(result instanceof Error));

  const courseList: UcMyplanCRSPlnr[] = [];
  for (let i = 0; i < validValues.length; i++) {
    const value = validValues[i];
    const response = value?.UC_MYPLAN_RESP;

    if (response) {
      courseList.push(...response.UC_MYPLAN_CRS_PLNR);
    }
  }
  return courseList;
};

export const getShoppingCartResponse = async (
  strm: string,
  acadCareer: string
) => {
  const url = `${strm}/${acadCareer}`;
  const shoppingCartResponse = await (getDuService(
    {
      service: "classSearchService",
      method: "getShoppingCart",
    },
    url
  ) as Promise<ShoppingCartResponse>);

  const cartDetails = shoppingCartResponse?.ShoppingCartResp?.CartDetails;
  return cartDetails;
};

export const orderOnsiteClassFirst = (
  courseList: CourseSection[],
  adelphiStudent?: boolean
) => {
  // revert order onsite first, for overseas students only
  if (adelphiStudent) return courseList;
  courseList.forEach((course) => {
    course.Class = course.Class.sort((a, b) => {
      if (
        a.LOCATION_DESCR !== "Online via the Web" &&
        b.LOCATION_DESCR === "Online via the Web"
      ) {
        return -1;
      }
      if (
        a.LOCATION_DESCR !== "Online via the Web" &&
        b.LOCATION_DESCR === "Online via the Web"
      ) {
        return 1;
      }
      return 0;
    });
    return course;
  });
  return courseList;
};

export enum GradingEnum {
  Graded = "GRD",
  Audid = "AUD",
  PassFail = "PNP",
}

export interface CustomWindow extends Window {
  dataLayer: any;
}

export const sendEvents = () => {
  // wait for 200ms until dataLayer loads
  setTimeout(() => {
    const dataLayer = (window as unknown as CustomWindow).dataLayer;
    if (!dataLayer) return;

    // access rootScope values from outside angular
    const ngAppEle = angular.element(document.querySelector("[data-ng-app]"));
    const injector = ngAppEle.injector();
    const $rootScope = injector.get("$rootScope");

    let subSection = "";
    if (/.+\/(\w+)/.test(window.location.href)) {
      subSection = /.+\/(\w+)/.exec(window.location.href)![1];
    }

    dataLayer.push({
      event: "PageView",
      page_name: window.location.pathname,
      site_section: "student portal",
      site_subsection1: subSection,
      URL: window.location.href,
      URL_parameter: window.location.search,
      user_id: $rootScope.headerProfileEmplid,
    });
  }, 200);
};

export const sendEventsCustomPayload = (payload: any) => {
  // wait for 200ms until dataLayer loads
  setTimeout(() => {
    const dataLayer = (window as unknown as CustomWindow).dataLayer;
    if (!dataLayer) return;

    dataLayer.push(payload);
  }, 100);
};
